// All Modals use the same default styles.
// modalStyle='modalStyle' and className='main-modal'

import Modal from 'react-modal'
import { withRegistration } from '../../Context/RegistrationProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DeleteParticipantModal = ({
	handleDeleteParticipant,
	toggleDeleteParticipantModal,
	isShowingDeleteParticipantModal,
	addParticipant,
	editingParticipantIndex,
}) => {
	let index = editingParticipantIndex

	const deletePaticipant = () => {
		handleDeleteParticipant(index)
		toggleDeleteParticipantModal()
	}

	return (
		<div>
			<Modal
				isOpen={isShowingDeleteParticipantModal}
				contentLabel='Delete participant'
				modalStyle='modalStyle'
				className='main-modal'
				overlayClassName='main-modal-overlay'
				ariaHideApp={false}
			>
				<div
					className='main-modal-body'
					data-test='edit-user-modal-body'
				>
					<div className='close-icon-wrapper'>
						<FontAwesomeIcon
							icon={'times-circle'}
							className='close-icon'
							onClick={toggleDeleteParticipantModal}
						/>
					</div>
					<h3>
						Are you sure you want to remove{' '}
						{addParticipant.firstName} {addParticipant.lastName}?
					</h3>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-evenly',
							flexWrap: 'wrap',
						}}
					>
						<button
							onClick={toggleDeleteParticipantModal}
							className='button-primary-r'
							style={{ margin: '0 5px' }}
						>
							CANCEL
						</button>
						<button
							style={{ margin: '0 5px' }}
							onClick={deletePaticipant}
							className='button-red'
						>
							DELETE
						</button>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default withRegistration(DeleteParticipantModal)
