import { useState, useEffect } from 'react'

const getWidth = () =>
	window.innerWidth ||
	document.documentElement.clientWidth ||
	document.body.clientWidth

export function useCurrentWidth() {
	let [width, setWidth] = useState(getWidth())

	useEffect(() => {
		let timeoutId = null

		// timeout before running function to be more performant
		const resizeListener = () => {
			clearTimeout(timeoutId)
			timeoutId = setTimeout(() => setWidth(getWidth()), 100)
		}

		window.addEventListener('resize', resizeListener)

		return () => {
			window.removeEventListener('resize', resizeListener)
		}
	}, [])

	return width
}

export const useResponsivity = (size) => {
  const [isOnMobile, setIsOnMobile] = useState(true);
  useEffect(() => {
    /*Check the screen size on every render, prevent possible bugs by
    checking the screen size and setting the isOnMobile when necessary.
    */
    function handleResize() {
      if (window.innerWidth >= size) {
        setIsOnMobile(false);
      }
      else { setIsOnMobile(true) }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    //Cleanup the useEffect when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return isOnMobile;
}
