import moment from 'moment'
import footerData from './footerData.json'
import FooterList from './FooterList'
import linkedInLogo from '../../Assets/linkedIn.svg'
import facebookLogo from '../../Assets/facebook.svg'
import twitterLogo from '../../Assets/twitter.svg'
import youtubeLogo from '../../Assets/youtube.svg'
import instagramLogo from '../../Assets/instagram.svg'

const Footer = () => {
	const copywriteDate = moment().format('YYYY')
	const socialIcons = [
		{
			name: 'LinkedIn',
			link: 'https://www.linkedin.com/company/insideout-development/',
			logo: linkedInLogo
		},
		{
			name: 'Facebook',
			link: 'https://www.facebook.com/InsideOutDev/',
			logo: facebookLogo
		},
		{
			name: 'Twitter',
			link: 'https://twitter.com/insideoutdev',
			logo: twitterLogo
		},
		{
			name: 'Youtube',
			link: 'https://www.youtube.com/user/InsideOutDev/featured',
			logo: youtubeLogo
		},
		{
			name: 'Instagram',
			link: 'https://www.instagram.com/insideoutdev/',
			logo: instagramLogo
		}
	]

	const socialMap = socialIcons.map(icon => (
		<li key={icon.name}>
			<a href={icon.link} target='_blank' rel='noopener'>
				<img src={icon.logo} alt={icon.name} />
			</a>
		</li>
	))


	return (
		<footer id='footer'>
			<div className='footer-wrap'>
				<div className='footer-top'>
					<a href='https://insideoutdev.com' className='footer-top-logo'>
						<img src='https://insideoutdev.com/hubfs/InsideOut/Images/logo.svg' alt='logo' />
					</a>
					<div className='footer-top-right'>
						<div className='contact-item'>
							<div className='contact-item-icon'>
								<img
									src='https://insideoutdev.com/hubfs/InsideOut/Images/pin.svg'
									alt='pin'
									loading='lazy'
									width='11'
									height='17'
								/>
							</div>
							<div className='contact-item-text'>
								270 N Main St Suite B <br /> Alpine,
								UT 84004
							</div>
						</div>

						<div className='contact-item'>
							<div className='contact-item-icon'>
								<img
									src='https://insideoutdev.com/hubfs/InsideOut/Images/envelope.svg'
									alt='envelope'
									loading='lazy'
								/>
							</div>
							<div className='contact-item-text'>
								<a href='mailto:info@insideoutdev.com'>
									info@insideoutdev.com
								</a>
							</div>
						</div>

						<div className='contact-item'>
							<div className='contact-item-icon'>
								<img
									src='https://insideoutdev.com/hubfs/InsideOut/Images/call.svg'
									alt='call'
									loading='lazy'
									width='15'
									height='16'
								/>
							</div>
							<div className='contact-item-text'>
								<a href='tel:(866)%20599-1361'>
									(866) 599-1361
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='footer-middle'>
					<div aria-label='Navigation Menu'>
						<ul className='footer-navigation'>
							<FooterList data={footerData.solutions1} newTabs={false} />
							<FooterList data={footerData.solutions2} newTabs={false} />
							<FooterList data={footerData.resources} newTabs={false} />
							<FooterList data={footerData.company} newTabs={false} />
							<FooterList data={footerData.portals} newTabs={true} />
						</ul>
					</div>
					<div className='footer-social-icons'>
						<ul className='dis-flex vmiddle'>
							{socialMap}
						</ul>
					</div>
				</div>
				<div className='footer-bottom'>
					<div className='footer-bottom-left'>
						<ul>
							<li>
								<a href='https://insideoutdev.com/cookie-policy'>Cookie Policy</a>
							</li>
							<li>|</li>
							<li>
								<a href='https://insideoutdev.com/privacy-policy'>Privacy Policy</a>
							</li>
						</ul>
					</div>
					<div className='footer-bottom-right'>
						<p>
							Copyright © {copywriteDate} InsideOut Development, all
							rights reserved.
						</p>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
