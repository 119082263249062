import { useEffect } from 'react'
import { withEvents } from '../../Context/EventsProvider'
import { withRegistration } from '../../Context/RegistrationProvider'
import Moment from 'react-moment'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditParticipantModal from '../Modals/EditParticipantModal'
import EditBillingContactModal from '../Modals/EditBillingContactModal'
import EditBillingAddressModal from '../Modals/EditBillingAddressModal'
import DeleteParticipantModal from '../Modals/DeleteParticipantModal'
import OrderConfirmationImage from '../../Assets/order-confirmation.svg'

const OrderReviewAndConfirmation = ({
	participants,
	currentStep,
	selectedEvent,
	billingFirstName,
	billingLastName,
	registrationEventDetails,
	billingTitle,
	billingCompany,
	billingStreetOne,
	billingStreetTwo,
	billingCity,
	billingState,
	billingZipCode,
	billingPhone,
	billingEmail,
	orderConfirmationNumber,
	promoCode,
	defaultEventPrice,
	totalPrice,
	handleBackStep,
	handleNextStep,
	toggleEditParticipantModal,
	toggleEditBillingContactModal,
	toggleEditBillingAddressModal,
	toggleDeleteParticipantModal,
	windowReset,
	isGROW
}) => {
	const {
		Delivery_Format__c,
		City__c,
		State__c,
		X1_Day_Start_DateTime__c,
		X2_Day_Start_date_time__c,
		X3_Day_End_date_time__c,
		Campaign_Class__c,
		AddEvent_Time_zone__c,
	} = selectedEvent

	const timeZone =
		AddEvent_Time_zone__c &&
		moment(X1_Day_Start_DateTime__c).tz(AddEvent_Time_zone__c).format('z')
	const promoDeduction = defaultEventPrice * participants.length - totalPrice
	const reviewLocation =
		Delivery_Format__c === 'Virtual'
			? `Virtual Classroom - ${timeZone} `
			: `${City__c}, ${State__c}`

	useEffect(() => {
		if (window.innerWidth > 480) {
			windowReset()
		} else {
			window.scrollTo(0, 0)
		}
	}, [windowReset])

	const reviewParticipants = participants.map((person) => {
		const index = participants.indexOf(person)
		return (
			<ul
				key={`Participant-${index}`}
				index={index}
				className='no-list event-review-section'
			>
				{(currentStep === 2 || currentStep === 4) && (
					<li>
						<div></div>
						<aside>
							<button
								className='edit-button'
								onClick={() =>
									toggleEditParticipantModal(index)
								}
							>
								<FontAwesomeIcon
									icon='edit'
									title='Edit Participant'
								/>
							</button>
							{participants.length > 1 && (
								<button
									className='delete-button'
									onClick={() =>
										toggleDeleteParticipantModal(index)
									}
								>
									<FontAwesomeIcon
										icon='trash-alt'
										title='Delete Participant'
									/>
								</button>
							)}
						</aside>
					</li>
				)}
				<li>
					<p>Name: </p>
					<strong>
						{person.firstName} {person.lastName}
					</strong>
				</li>
				<li>
					<p>Email:</p>
					{person.email}
				</li>
				<li>
					<p>Company:</p>
					{person.company}
				</li>
				<li>
					<p>Title:</p>
					{person.title}
				</li>
				<li>
					<p>Phone:</p>
					{person.phone}
				</li>
			</ul>
		)
	})

	return (
		<section>
			<EditParticipantModal />
			<EditBillingContactModal />
			<EditBillingAddressModal />
			<DeleteParticipantModal />

			{/* Confirmation */}
			{currentStep !== 2 && currentStep !== 4 && (
				<div>
					<article className='event-registrations-confirmation-banner'>
						<img
							src={OrderConfirmationImage}
							alt='Order Completed'
						/>
						<span>
							<p>
								In preparation for your workshop, complete our
								simple five-step process to make sure you get
								the most from your {isGROW ? 'GROW' : 'InsideOut'} Coaching
								experience.
							</p>
							<p>
								Keep an eye on your inbox for important
								information and instructions about your workshop
								and how to prepare.
							</p>
						</span>
					</article>

					<section>
						<h3>Registration Complete</h3>
						<p>
							Thank you for registering. You will receive an order
							confirmation email shortly. All registered
							participants will receive an email detailing
							important workshop preparation information and
							instructions. If you don't receive these emails
							within the hour, please check your junk folder and
							add InsideOut Development to your email contact
							list.
						</p>
						<p>
							For additional support, contact{' '}
							<a href='mailto:events@insideoutdev.com'>
								events@insideoutdev.com
							</a>{' '}
							or <a href='tel:1-888-262-2448'>888-262-2448</a>.
						</p>
						<button
							className='button-primary'
							style={{
								margin: '30px 0 10px 0',
								alignSelf: 'flex-start',
							}}
							onClick={() => window.print()}
						>
							PRINT
						</button>
					</section>
				</div>
			)}

			<section>
				<h3>Review</h3>
				{Campaign_Class__c !== 'Breakthroughs' ? (
					<ul className='no-list'>
						<li>
							<strong>{reviewLocation}</strong>
						</li>
						<li>{isGROW ? 'GROW' : 'InsideOut Coaching'} {registrationEventDetails}</li>
						<li>
							{registrationEventDetails === 'Workshop' ? (
								<Moment format='dddd, ll'>
									{X1_Day_Start_DateTime__c}
								</Moment>
							) : registrationEventDetails === 'Certification' ? (
								<>
									<Moment format='dddd, ll'>
										{X2_Day_Start_date_time__c}
									</Moment>
									{' - '}
									<Moment format='dddd, ll'>
										{X3_Day_End_date_time__c}
									</Moment>
								</>
							) : (
								<>
									<Moment format='dddd, ll'>
										{X1_Day_Start_DateTime__c}
									</Moment>
									{' - '}
									<Moment format='dddd, ll'>
										{X3_Day_End_date_time__c}
									</Moment>
								</>
							)}
						</li>
					</ul>
				) : (
					<ul className='no-list'>
						<li>
							<strong>Online</strong>
						</li>
						<li>{registrationEventDetails}</li>
						<li>
							<Moment format='dddd, ll'>
								{X2_Day_Start_date_time__c}
							</Moment>
						</li>
					</ul>
				)}
			</section>

			{(currentStep === 2 || currentStep === 4 || currentStep === 6) && (
				<section>
					<h3>Registered Participants</h3>
					{reviewParticipants}
				</section>
			)}

			{(currentStep === 4 || currentStep === 6) && (
				<section>
					<h3>Billing Information:</h3>
					<ul className='no-list event-review-section'>
						{currentStep === 4 && (
							<li>
								<div></div>
								<aside>
									<button className='edit-button'>
										<FontAwesomeIcon
											icon='edit'
											title='Edit Billing'
											onClick={
												toggleEditBillingContactModal
											}
										/>
									</button>
								</aside>
							</li>
						)}
						<li>
							<p>Name:</p>
							<strong>
								{billingFirstName} {billingLastName}
							</strong>
						</li>
						<li>
							<p>Email:</p>
							{billingEmail}
						</li>
						<li>
							<p>Company:</p>
							{billingCompany}
						</li>
						<li>
							<p>Title:</p>
							{billingTitle}
						</li>
						<li>
							<p>Phone:</p>
							{billingPhone}
						</li>
					</ul>

					<ul className='no-list event-review-section'>
						{currentStep === 4 && (
							<li>
								<div></div>
								<aside>
									<button className='edit-button'>
										<FontAwesomeIcon
											icon='edit'
											title='Edit Billing'
											onClick={
												toggleEditBillingAddressModal
											}
										/>
									</button>
								</aside>
							</li>
						)}
						<li>
							<p>Address:</p>
							{billingStreetOne} {billingStreetTwo}
						</li>
						<li>
							<p>City:</p>
							{billingCity}
						</li>
						<li>
							<p>State:</p>
							{billingState}
						</li>
						<li>
							<p>Zipcode:</p>
							{billingZipCode}
						</li>
					</ul>
				</section>
			)}
			{currentStep === 6 && (
				<section>
					<h3>Payment Information</h3>
					<ul className='no-list event-review-section'>
						<li>
							<span style={{ fontWeight: '600' }}>Order ID:</span>
						</li>
						<li style={{ marginBottom: '10px' }}>
							<span>{orderConfirmationNumber}</span>
						</li>
						<li style={{ marginBottom: '10px' }}>
							<span style={{ fontWeight: '600' }}>
								Event: ({participants.length}){' '}
								{registrationEventDetails} ${defaultEventPrice}
							</span>
						</li>

						<li style={{ marginBottom: '10px' }}>
							<span style={{ fontWeight: '600' }}>
								Promo: {promoCode} (${promoDeduction})
							</span>
						</li>

						<li style={{ marginBottom: '10px' }}>
							<span style={{ fontWeight: '600' }}>
								Total: ${totalPrice}{' '}
							</span>
						</li>

						<li style={{ marginBottom: '10px' }}>
							<span style={{ fontWeight: '600' }}>
								Transaction Date: <Moment format='LL'></Moment>
							</span>
						</li>
					</ul>
				</section>
			)}

			{currentStep === 2 || currentStep === 4 ? (
				<nav style={{ display: 'flex', marginTop: '20px' }}>
					<button
						onClick={handleBackStep}
						className='button-primary-r'
						style={{
							borderBottomRightRadius: '0',
							borderTopRightRadius: '0',
							marginRight: '1px',
							width: '50%',
						}}
					>
						BACK
					</button>
					<button
						onClick={handleNextStep}
						className='button-primary'
						style={{
							borderBottomLeftRadius: '0',
							borderTopLeftRadius: '0',
							marginLeft: '1px',
							width: '50%',
						}}
					>
						FORWARD
					</button>
				</nav>
			) : (
				<>
					<div>
						<p>Questions about your registration?</p>
						<p>
							{' '}
							Contact us at{' '}
							<a href='mailto:events@insideoutdev.com'>
								events@insideoutdev.com
							</a>{' '}
							or <a href='tel:1-888-262-2448'>888-262-2448</a>
						</p>
					</div>
					<nav>
						<a href='https://insideoutdev.com/events'>
							<button className='button-highlight'>DONE</button>
						</a>
					</nav>
				</>
			)}
		</section>
	)
}

export default withEvents(withRegistration(OrderReviewAndConfirmation))
