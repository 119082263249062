import { useFacilitatorRequest } from '../../Context/FacilitatorRequestProvider'
import MeetingLink from './MeetingLink'

export const AdditionalInfo = () => {
	const { requestState, logisticsFormOnChange, sessions, updateMeetingLink } = useFacilitatorRequest()

	const isOnsite = requestState.Delivery_Location__c === 'Onsite'

	let showSnapshot = requestState.Product_Being_Delivered__c === 'InsideOut Coaching Workshop' || requestState.Product_Being_Delivered__c === 'InsideOut Coaching T3'

	let showMeetingLink = requestState.Delivery_Location__c === 'Client providing link'

	return (
		<section className='outline-section'>
			<div>
				<header>
					<strong>Additional Information</strong>
				</header>
				{showMeetingLink &&
					<div>
						<header>Meeting Links</header>
						{sessions.length > 0 ?
							<>
							{sessions.map((session, i) => (
								<MeetingLink 
									key={session.AddEvent_Unique_Key__c}
									session={session}
									updateMeetingLink={updateMeetingLink} 
								/>
							))}
							</>
							:
							<label>
								<p style={{color: '#FF474C', margin: '10px 0'}}>No Sessions exist, please contact your coordinator.</p>
							</label>
						}
					</div>
				}
				{isOnsite &&
					<label>
						Location and room #
						<input
							name='Meeting_Location_and_Room__c'
							type='text'
							required
							onChange={logisticsFormOnChange}
							defaultValue={requestState.Meeting_Location_and_Room__c}
						/>
					</label>
				}
				<label>
					Dress Code
					<input
						name='Dress_Code__c'
						type='text'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Dress_Code__c}
					/>
				</label>
				{showSnapshot &&
					<label>
						Coaching Snapshot being used?
						<input
							name='Coaching_Snapshot_set_up_if_applicable__c'
							type='checkbox'
							onChange={logisticsFormOnChange}
							defaultValue={requestState.Coaching_Snapshot_set_up_if_applicable__c}
						/>
					</label>
				}

				<label>
					Notes for the Session
					<textarea
						name='Notes_for_the_session__c'
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Notes_for_the_session__c}
					/>
				</label>
			</div>
		</section>
	)
}
