import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Hero from '../Components/Hero'
import BackgroundCircles from '../Components/BackgroundCircles'
import Loader from '../Components/Loader'
import LogisticsLanding from './Logistics'
import { useFacilitatorRequest } from '../Context/FacilitatorRequestProvider'

const FacilitatorRequestWrap = () => {
  const { id } = useParams()
	const { loading, requestState, message, getRequestForm } = useFacilitatorRequest()

	useEffect(() => {
		getRequestForm(id)
	}, [id])

	const accountName = requestState.Account__c

  return (
    <main className='calendar-confirmation '>
      <BackgroundCircles />
			<Hero titleText={accountName ? `${accountName} - Program Logistics` : 'Program Logistics'} subtitle={requestState.Training_Session_Name__c ?	requestState.Training_Session_Name__c : ''} />
			{loading ?
				<div className='loader-wrap'>
					<Loader />
				</div>
			: message.length > 0 ? (
				<p className='tc'>
					{message}
				</p>
			) : (
				<LogisticsLanding facilitatorRequest={requestState} />
			)}
    </main>
  )
}

export default FacilitatorRequestWrap
