// All Modals use the same default styles.
// modalStyle='modalStyle' and className='main-modal'

import Modal from 'react-modal'
import { withRegistration } from '../../Context/RegistrationProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EditParticipantModal = ({
	isShowingEditUserModal,
	toggleEditParticipantModal,
	addParticipant,
	handleEditParticipant,
	handleUpdateEditedParticipant,
	editingParticipantIndex,
}) => {
	let index = editingParticipantIndex

	const updateParticipant = (e) => {
		e.preventDefault()
		handleUpdateEditedParticipant()
	}

	return (
		<>
			{index != null ? (
				<Modal
					isOpen={isShowingEditUserModal}
					contentLabel='Edit participant'
					modalStyle='modalStyle'
					className='main-modal'
					overlayClassName='main-modal-overlay'
					ariaHideApp={false}
				>
					<div className='main-modal-body'>
						<div className='close-icon-wrapper'>
							<FontAwesomeIcon
								icon={'times-circle'}
								className='close-icon'
								onClick={toggleEditParticipantModal}
							/>
						</div>
						<h3 className='tc'>
							Editing{' '}
							{index >= 0 ? addParticipant.firstName : null}{' '}
							{index >= 0 ? addParticipant.lastName : null}:{' '}
						</h3>
						<form onSubmit={updateParticipant} autoComplete='off'>
							<fieldset className='form-columns'>
								<label>
									First Name:
									<input
										name='firstName'
										type='text'
										autoComplete='off'
										value={addParticipant.firstName}
										onChange={handleEditParticipant}
									/>
								</label>
								<label>
									Last Name:
									<input
										name='lastName'
										type='text'
										autoComplete='off'
										value={addParticipant.lastName}
										onChange={handleEditParticipant}
									/>
								</label>
							</fieldset>

							<fieldset className='form-columns'>
								<label>
									Email:
									<input
										name='email'
										type='email'
										autoComplete='off'
										value={addParticipant.email}
										onChange={handleEditParticipant}
									/>
								</label>

								<label>
									Phone:
									<input
										name='phone'
										type='tel'
										autoComplete='off'
										pattern='[0-9-_.]{1,30}'
										value={addParticipant.phone}
										onChange={handleEditParticipant}
									/>
								</label>
							</fieldset>
							<fieldset className='form-columns'>
								<label>
									Company:
									<input
										name='company'
										type='text'
										autoComplete='off'
										value={addParticipant.company}
										onChange={handleEditParticipant}
									/>
								</label>
								<label>
									Title:
									<input
										name='title'
										type='text'
										autoComplete='off'
										value={addParticipant.title}
										onChange={handleEditParticipant}
									/>
								</label>
							</fieldset>
							<button
								className='button-secondary'
								style={{ margin: '0 auto' }}
							>
								{' '}
								Update Participant{' '}
							</button>
						</form>
					</div>
				</Modal>
			) : null}
		</>
	)
}

export default withRegistration(EditParticipantModal)
