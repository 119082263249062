import { withEvents } from '../../Context/EventsProvider'
import { withRegistration } from '../../Context/RegistrationProvider'

const EventParticipantForm = ({
	handleChangeParticipant,
	addParticipant,
	participants,
}) => {
	const isRequired = participants.length === 0 ? true : false

	return (
		<section>
			<h3>Participant Information</h3>
			<fieldset className='form-columns'>
				<label htmlFor='firstName'>
					<p>First Name:</p>
					<input
						type='text'
						name='firstName'
						required={isRequired}
						onChange={handleChangeParticipant}
						value={addParticipant.firstName}
					/>
				</label>
				<label htmlFor='lastName'>
					<p>Last Name:</p>
					<input
						type='text'
						name='lastName'
						required={isRequired}
						onChange={handleChangeParticipant}
						value={addParticipant.lastName}
					/>
				</label>
			</fieldset>
			<fieldset className='form-columns'>
				<label htmlFor='email'>
					<p>Email:</p>
					<input
						type='email'
						name='email'
						required={isRequired}
						onChange={handleChangeParticipant}
						value={addParticipant.email}
					/>
				</label>
				<label htmlFor='phone'>
					<p>Phone Number:</p>
					<input
						type='tel'
						name='phone'
						pattern='[0-9-_.+]{1,30}'
						required={isRequired}
						onChange={handleChangeParticipant}
						value={addParticipant.phone}
					/>
				</label>
			</fieldset>
			<fieldset className='form-columns'>
				<label htmlFor='company'>
					<p>Company:</p>
					<input
						type='text'
						name='company'
						required={isRequired}
						onChange={handleChangeParticipant}
						value={addParticipant.company}
					/>
				</label>
				<label htmlFor='title'>
					<p>Title:</p>
					<input
						type='text'
						name='title'
						required={isRequired}
						onChange={handleChangeParticipant}
						value={addParticipant.title}
					/>
				</label>
			</fieldset>
		</section>
	)
}

export default withEvents(withRegistration(EventParticipantForm))
