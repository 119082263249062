import BackgroundImage from '../Assets/hero-background.svg'

const Hero = ({ titleText, subtitle, caption }) => {
  return (
    <section className="hero-banner-section tc">
      <div className="content-wrapper">
        <div className="hero-banner-inner" style={{ minHeight:'60px' }}>
          <div>
            <h1 className="no-margin">
              {titleText}
            </h1>
            {subtitle &&
              <h2>{subtitle}</h2>
            }
            {caption &&
              <p>{caption}</p>
            }
          </div>
        </div>
      </div>
      
      <div className="hero-banner-img-wrapper">
        <img className="hero-banner-img" src={BackgroundImage} width="1440" height="810" />
        <div className="banner-overlay"></div>
      </div>
    </section>
  )
}

export default Hero
