import { useState, useContext, createContext } from 'react'
import axios from 'axios'
import Loader from '../Components/Loader'

const facilitatorReqBaseURL = '/v1/salesforce/facilitator-requests'
const FacilitatorRequestContext = createContext()

export const useFacilitatorRequest = () => useContext(FacilitatorRequestContext)

const defaultLogisticsFormState = {
	Main_Logistics_Contact__c: null,
	Main_Logistics_Contact_Phone__c: null,
	Main_Logistics_Contact_Email__c: null,
	Main_Logistics_Contact_Cell__c: null,
	Onsite_Contact__c: null,
	Onsite_Contact_Email__c: null,
	Onsite_Contact_Cell__c: null,
	Meeting_Location_and_Room__c: null,
	Dress_Code__c: null,
	Ship_to_Address__c: null,
	Bill_to_Address__c: null,
	Bill_to_Email__c: null,
	PO_if_applicable__c: null,
	Recommended_Airport__c: null,
	Recommended_Ground_Transportation__c: null,
	Recommended_Hotel__c: null,
	Airport_to_Hotel__c: null,
	Hotel_to_Program_Location__c: null,
	Program_Location_to_Airport__c: null,
	Attendees__c: null,
	of_Materials_to_be_Shipped__c: null,
	Coaching_Snapshot_set_up_if_applicable__c: null,
	Logistics_submitted_on__c: new Date().toISOString(),
	Meeting_Links: null
}

export const FacilitatorRequestProvider = ({ children }) =>  {
  const [sessions, setSessions] = useState([])
	const [loading, setLoading] = useState(true)
	const [requestState, setRequestState] = useState({})
  const [message, setMessage] = useState('')
	const [pageToDisplay, setPageToDisplay] = useState('loading')
  const [submitted, setSubmitted] = useState(null)
	const [submitting, setSubmitting] = useState(null)
  const [logisticsFormState, setLogisticsFormState] = useState(defaultLogisticsFormState)
	const [confirmedAll, setConfirmedAll] = useState(false)

  const finishLoading = (message) => {
		setMessage(message)
		setLoading(false)
	}

	const getRequestForm = async (id) => {
		try {
			let res = await axios.get(`${facilitatorReqBaseURL}/lookup/${id}`)
			let requestData = res.data.record
			if (requestData) {
				// remove unneeded keys
				delete requestData.attributes
				let sessions = await getAddEventSessions(requestData)
				setRequestState(requestData)
				setSessions(sessions)
				if(requestData.Logistics_submitted_on__c){
					finishLoading('Your Logistics form has already been submitted. Contact your coordinator if you have any further questions.')
				} else {
					finishLoading('')
				}
			} else {
				finishLoading('There is no form associated with this URL. Please contact your coordinator to check your facilitator request link.')
			}
		} catch (error) {
			console.error(error)
			finishLoading('Unable to retrieve the form for your account. Please contact your coordinator if the problem persists.')
		}
	}

	const getAddEventSessions = async (requestData) => {
		try {
			let res = await axios.get(`${facilitatorReqBaseURL}/lookup/sessions/${requestData.Id}`)
			let data = res.data.records
			delete data.attributes
			let eventSessions = data.map(({attributes, ...keepAttrs}) => ({...keepAttrs, confirmed: null}))
			return eventSessions
		} catch (error) {
			console.error(error)
			return []
		}
	}

	const updateMeetingLink = (updatedSession) => {
		let existingSessions = sessions
		let sessionIndex = existingSessions.findIndex(obj => obj.Id === updatedSession.Id)
		existingSessions[sessionIndex] = updatedSession
		setSessions(existingSessions)
	}

	const updateMeetingLinksInSF = async (session) => {
		try {
			await axios.patch(`/v1/salesforce/addevent-sessions/submit/${session.Id}`, 
			{ Meeting_Link__c: session.Meeting_Link__c })
		} catch (error) {
			console.error(error)
			setSubmitted(false)
		}
	}

  const updateSession = (updatedSession) => {
		let existingSessions = sessions
		let sessionIndex = existingSessions.findIndex(obj => obj.Id === updatedSession.Id)
		existingSessions[sessionIndex] = updatedSession
		setSessions(existingSessions)
		checkSessionConfirmations()
	}

	const checkSessionConfirmations = () => {
		let unconfirmedSessions = sessions.filter(session => !session.confirmed)
    if(unconfirmedSessions.length > 0){
      setConfirmedAll(false)
    } else {
      setConfirmedAll(true)
    }
	}

	const displayLogisticsForm = () => {
    setSubmitted(null)
    setSubmitting(null)
		setPageToDisplay('logistics')
	}

  const updateSessionsInSF = async (session) => {
		try {
			await axios.patch(`/v1/salesforce/addevent-sessions/submit/${session.Id}`, 
			{ Client_Scheduling_Feedback__c: session.Client_Scheduling_Feedback__c })
		} catch (error) {
			console.error(error)
			setSubmitted(false)
		}
	}

	const confirmationOnSubmit = async (e) => {
		e.preventDefault()
		setSubmitting(true)
		const updatedSessions = sessions.filter(session => !session.confirmed)
		let runSessionUpdates = updatedSessions.length > 0

		try {
			if(runSessionUpdates){
				for (const session of updatedSessions) {
					await updateSessionsInSF(session)
				}
			}

			await axios.patch(
				`${facilitatorReqBaseURL}/submit/${requestState.Id}`,
				{ Client_Confirmation_Submitted_On__c: new Date().toISOString() }
			)
			
			setSubmitting(false)
			setSubmitted(true)
			if(!runSessionUpdates) displayLogisticsForm()
		} catch (error) {
			console.error(error)
			setSubmitted(false)
			setSubmitting(false)
		}
	}

	const logisticsFormOnSubmit = async (e) => {
		e.preventDefault()
		setSubmitting(true)

		// remove null values from update object.
		let updateObject = Object.fromEntries(Object.entries(logisticsFormState).filter(([_, v]) => v != null))

		try {
			await axios.patch(`${facilitatorReqBaseURL}/submit/${requestState.Id}`, updateObject)
			if(sessions.length > 0){
				for (const session of sessions) {
					await updateMeetingLinksInSF(session)
				}

				setSubmitting(false)
				setSubmitted(true)
			} else {
				setSubmitting(false)
				setSubmitted(true)
			}
		} catch (error) {
			console.error(error)
			setSubmitting(false)
			setSubmitted(false)
		}
	}

  const logisticsFormOnChange = (e) => {
		const { name, value } = e.target
		setLogisticsFormState({ ...logisticsFormState, [name]: value })
	}

  const submissionMessage = submitted === true ? 'Form successfully submitted. It is now safe to close this page.' : submitted === false && 'Unable to submit your form. Please contact your coordinator.'

  const cursorStyle = submitting ? 'progress' : submitted ? 'default' : 'pointer'
	const submitButton = <button 
		type='submit' 
		className='button-primary' 
		disabled={submitted || submitting}
		style={{cursor: cursorStyle}}
	>
		{submitting ? <Loader /> : 'Submit'}
	</button>

  return (
    <FacilitatorRequestContext.Provider 
      value={{ 
        loading,
        sessions,
        requestState,
        message, 
        pageToDisplay,
        submitted,
        submitting,
        submissionMessage,
        cursorStyle,
        logisticsFormState,
				submitButton,
				confirmedAll,
        logisticsFormOnChange,
        getRequestForm,
        updateSession, 
        displayLogisticsForm,
        confirmationOnSubmit,
        logisticsFormOnSubmit,
				updateMeetingLink
      }}>
      { children }
    </FacilitatorRequestContext.Provider>
  )
}
