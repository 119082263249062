import { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { PublicRoute }  from './shared/PublicRoute'
import { ExternalRedirect } from './shared/ExternalRedirect'
import { rootDomain } from './config/Config'
import Loader from './Components/Loader'
import FacilitatorRequestWrap from './FacilitatorRequests/FacilitatorRequestWrap'
import EventRegistrations from './Registrations/EventRegistration'
import PromoRegistrations from './ProspectRegistration/PromoRegistration'
import WorkbookGate from './WorkbookGate/WorkbookGate'
import './Assets/FaIcons'


const App = () => {
  return (
    <div className='App'>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute path='/facilitator-request/:id' component={FacilitatorRequestWrap} />
          <PublicRoute path='/register/:id' component={EventRegistrations} />
          <PublicRoute path='/register-promo/:id' component={PromoRegistrations} />
          <Route path='/content-gate/:id' component={WorkbookGate} />
          <PublicRoute path='*' component={() => ExternalRedirect(`${rootDomain}/events`)} />

          {/* <PublicRoute path='*' component={() => <div></div>} /> */}
        </Switch>
      </Suspense>
    </div>
  )
}

export default App
