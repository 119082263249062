import { useEffect } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { withEvents } from '../../Context/EventsProvider'
import { withRegistration } from '../../Context/RegistrationProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BillingInfo = ({
	handleChangeRegistration,
	promoCode,
	checkPromoCode,
	participants,
	isPromocodeValid,
	promoCodeError,
	removePromoCode,
	useParticipantInfo,
	toggleUseParticipantInfo,
	cancelUseParticipantInfo,
	billingFirstName,
	billingLastName,
	billingTitle,
	billingCompany,
	billingStreetOne,
	billingStreetTwo,
	billingCity,
	billingState,
	billingCountry,
	billingZipCode,
	billingPhone,
	billingEmail,
	handleNextStep,
	handleBackStep,
	handleSelectCountry,
	handleSelectState,
	windowReset,
	promoCodeNotification,
}) => {
	const participant = participants[0]

	useEffect(() => {
		return () => cancelUseParticipantInfo()
	}, [cancelUseParticipantInfo])

	useEffect(() => {
		windowReset()
	}, [windowReset])

	// if promo code is entered makes sure its valid then sends you to the next step,
	// if you empty the input and click next it will let you continue
	// BUG: have to click next twice if reentering a valid promo code.
	const handleAddPromoNextStep = (e) => {
		e.preventDefault()
		if (promoCode.length > 0) {
			checkPromoCode(e)
			if (isPromocodeValid === true) {
				handleNextStep()
			}
		} else if (!promoCode) {
			handleNextStep()
		}
	}

	const handleSetNextStep = (e) => {
		e.preventDefault()
		handleNextStep()
	}

	return (
		<section>
			<form
				onSubmit={
					!promoCode ? handleSetNextStep : handleAddPromoNextStep
				}
			>
				<section>
					<h3>PROMO CODE</h3>
					<fieldset>
						<input
							type='text'
							name='promoCode'
							onChange={handleChangeRegistration}
							value={promoCode}
							autoComplete='autocomplete_off'
						/>
					</fieldset>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
						<button
							type='button'
							className='button-primary'
							onClick={checkPromoCode}
							disabled={isPromocodeValid ? true : false}
							style={{
								padding: '2px 8px',
								marginRight: '10px',
								backgroundColor: isPromocodeValid && 'green',
							}}
						>
							{isPromocodeValid ? 'APPLIED' : 'APPLY'}
						</button>

						{isPromocodeValid && (
							<div>
								{promoCode}
								<FontAwesomeIcon
									onClick={removePromoCode}
									icon={'times'}
									style={{
										margin: '5px 0 0 5px',
										cursor: 'pointer',
									}}
								/>
							</div>
						)}
						{promoCodeError && (
							<div
								className='error-warning'
								style={{ marginTop: '5px', color: 'red' }}
							>
								{promoCodeError}
							</div>
						)}
						{isPromocodeValid === null || isPromocodeValid === true ? (
							<></>
						) : (
							<div className='error-warning' style={{ color: 'red' }}>
								{promoCodeNotification}{' '}
							</div>
						)}
					</div>
				</section>

				<section>
					<h3>BILLING INFORMATION</h3>
					<fieldset>
						<label htmlFor='useParticipantInfo'>
							Use First Participants Information?
						</label>
						<input
							name='useParticipantInfo'
							type='checkbox'
							style={{marginLeft: '5px'}}
							onClick={toggleUseParticipantInfo}
						/>
					</fieldset>
					<fieldset className='form-columns'>
						<label htmlFor='billingFirstName'>
							<p>First Name*</p>
							<input
								type='text'
								name='billingFirstName'
								required={true}
								onChange={handleChangeRegistration}
								value={
									useParticipantInfo
										? participant.firstName
										: billingFirstName
								}
							/>
						</label>
						<label htmlFor='billingLastName'>
							<p>Last Name*</p>
							<input
								type='text'
								name='billingLastName'
								required={true}
								onChange={handleChangeRegistration}
								defaultValue={
									useParticipantInfo
										? participant.lastName
										: billingLastName
								}
							/>
						</label>
					</fieldset>
					<fieldset className='form-columns'>
						<label htmlFor='billingEmail'>
							<p>Work Email*</p>
							<input
								type='email'
								name='billingEmail'
								required={true}
								onChange={handleChangeRegistration}
								defaultValue={
									useParticipantInfo
										? participant.email
										: billingEmail
								}
							/>
						</label>
						<label htmlFor='billingPhone'>
							<p>Phone*</p>
							<input
								type='tel'
								name='billingPhone'
								pattern='[0-9-_.]{1,30}'
								required={true}
								onChange={handleChangeRegistration}
								defaultValue={
									useParticipantInfo
										? participant.phone
										: billingPhone
								}
							/>
						</label>
					</fieldset>
					<fieldset className='form-columns'>
						<label htmlFor='billingCompany'>
							<p>Company*</p>
							<input
								type='text'
								name='billingCompany'
								required={true}
								onChange={handleChangeRegistration}
								defaultValue={
									useParticipantInfo
										? participant.company
										: billingCompany
								}
							/>
						</label>
						<label htmlFor='billingTitle'>
							<p>Title*</p>
							<input
								type='text'
								name='billingTitle'
								required={true}
								onChange={handleChangeRegistration}
								defaultValue={
									useParticipantInfo
										? participant.title
										: billingTitle
								}
							/>
						</label>
					</fieldset>
				</section>

				<section>
					<h3 className='registrations-headers'>BILLING ADDRESS</h3>
					<fieldset>
						<label htmlFor='billingStreetOne'>
							<p>Street 1/PO Box*</p>
							<input
								type='text'
								name='billingStreetOne'
								required={true}
								onChange={handleChangeRegistration}
								defaultValue={billingStreetOne}
								maxLength='250'
							/>
						</label>
					</fieldset>
					<fieldset>
						<label htmlFor='billingStreetTwo'>
							<p>Street 2</p>
							<input
								type='text'
								name='billingStreetTwo'
								onChange={handleChangeRegistration}
								defaultValue={billingStreetTwo}
								maxLength='250'
							/>
						</label>
					</fieldset>

					<fieldset className='form-columns'>
						<label htmlFor='billingCity'>
							<p>City*</p>
							<input
								type='text'
								name='billingCity'
								required={true}
								onChange={handleChangeRegistration}
								defaultValue={billingCity}
								maxLength='250'
							/>
						</label>
						<label htmlFor='billingState'>
							<p>State*</p>
							<RegionDropdown
								defaultOptionLabel='Select State'
								country={billingCountry}
								value={billingState}
								required={true}
								onChange={(val) => handleSelectState(val)}
							/>
						</label>
						<label htmlFor='billingZipCode'>
							<p>Zip*</p>
							<input
								type='text'
								name='billingZipCode'
								required={true}
								onChange={handleChangeRegistration}
								defaultValue={billingZipCode}
							/>
						</label>
					</fieldset>
					<fieldset>
						<label htmlFor='billingCountry'>
							<p>Country*</p>
							<CountryDropdown
								value={billingCountry}
								priorityOptions={['US']}
								required={true}
								onChange={(val) => handleSelectCountry(val)}
							/>
						</label>
					</fieldset>
				</section>

				<nav style={{ display: 'flex', marginTop: '20px' }}>
					<button
						onClick={handleBackStep}
						className='button-primary-r'
						style={{
							borderBottomRightRadius: '0',
							borderTopRightRadius: '0',
							marginRight: '1px',
							width: '50%',
						}}
					>
						BACK
					</button>
					<button
						onClick={handleNextStep}
						className='button-primary'
						type='submit'
						style={{
							borderBottomLeftRadius: '0',
							borderTopLeftRadius: '0',
							marginLeft: '1px',
							width: '50%',
						}}
					>
						FORWARD
					</button>
				</nav>
			</form>
		</section>
	)
}

export default withEvents(withRegistration(BillingInfo))
