import { useState } from 'react'
import axios from 'axios'
const baseProspectRoute = '/v1/prospect-codes'

const AccessGate = ({ updateStorage }) => {
  const [codeInput, setCodeInput] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const clearErrors = () => {
    setCodeError(false)
    setErrorMessage('')
  }

  const getProspectCode = async () => {
    try {
      let res = await axios.get(`${baseProspectRoute}/${codeInput}`)
      return res.data
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const applyCode = async (prospectCode) => {
    const { id, timesUsed } = prospectCode
    let updatedCode = {
      timesUsed: +timesUsed + 1
    }

    try {
      await axios.put(`${baseProspectRoute}/${id}`, updatedCode)
    } catch (error) {
      setErrorMessage('Code could not be updated')
      setCodeError(true)
      return null
    }
  }

  const submitAccessCode = async (e) => {
    e.preventDefault()
    let prospectCode = await getProspectCode()
    if(prospectCode) {
      await applyCode(prospectCode)    
      sessionStorage.setItem('prospectCode', prospectCode.name)  
      updateStorage({hasAccess: true, submitted: false})
    } else {
      setErrorMessage('Invalid Code')
      setCodeError(true)
    }
  }

  return (
    <section className='prospect-registrations-access'>
      <h3>Access Code:</h3>

      <form onSubmit={submitAccessCode}>
        <input 
          name='prospect-code' 
          type='text' 
          value={codeInput} 
          onChange={(e) => setCodeInput(e.target.value)}
          onClick={clearErrors} // clears error state if user is trying again
          required 
        />

        <div style={{display: 'flex', marginTop: '20px', alignItems: 'center'}}>
          <button type='submit' className='button-primary'>Submit</button>
          {codeError &&
            <p className='registration-error'>{errorMessage}</p>
          }
        </div>
      </form>
    </section>
  )
}

export default AccessGate
