import { Route } from 'react-router-dom'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'

export const PublicRoute = ({ component: Component , ...rest}) => {
    return (
        <Route {...rest}  render={(props) => (
            <>
                <Header />
                <Component {...props} />
                <Footer />
            </>
        )}
        />
    )
}
