// Used in Onsite Only
import { useFacilitatorRequest } from '../../Context/FacilitatorRequestProvider'

export const Travel = () => {
	const { requestState, logisticsFormOnChange } = useFacilitatorRequest()

	return (
		<section className='outline-section'>
			<div>
				<header>
					<strong>Travel / Transportation</strong>
				</header>
				<label>
					Recommended Airport
					<input
						name='Recommended_Airport__c'
						type='text'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Recommended_Airport__c}
					/>
				</label>
				<label>
					Recommended Group Transportation
					<input
						name='Recommended_Ground_Transportation__c'
						type='text'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Recommended_Ground_Transportation__c}
					/>
				</label>
				<label>
					Recommended Hotel
					<input
						name='Recommended_Hotel__c'
						type='text'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Recommended_Hotel__c}
					/>
				</label>
				<label>
					Airport to Hotel
					<input
						name='Airport_to_Hotel__c'
						type='text'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Airport_to_Hotel__c}
					/>
				</label>
				<label>
					Hotel to Program Location
					<input
						name='Hotel_to_Program_Location__c'
						type='text'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Hotel_to_Program_Location__c}
					/>
				</label>
				<label>
					Program Location to Airport
					<input
						name='Program_Location_to_Airport__c'
						type='text'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Program_Location_to_Airport__c}
					/>
				</label>
			</div>
		</section>
	)
}
