import { useFacilitatorRequest } from '../Context/FacilitatorRequestProvider'
import { Contact } from './Fields/Contact'
import { Shipping } from './Fields/Shipping'
import { Travel } from './Fields/Travel'
import { AdditionalInfo } from './Fields/AdditionalInfo'
import { Materials } from './Fields/Materials'

const LogisticsLanding = () => {
	const { requestState, logisticsFormOnSubmit, submissionMessage, submitted, submitButton } = useFacilitatorRequest()

	const { 
		Delivery_Location__c,
		Logistics_form_Needs_Billing_Info__c, 
		Logistics_form_Needs_Shipping__c, 
		Logistics_form_Needs_PO__c, 
		Logistics_form_Needs_Travel_Info__c, 
		Logistics_form_Needs_Materials_info__c,
		RD_email__c,
		Delivery_Cancellation_Terms__c
	} = requestState

	const isOnsite = Delivery_Location__c === 'Onsite'
	const displayShipping = Logistics_form_Needs_Billing_Info__c || Logistics_form_Needs_Shipping__c || Logistics_form_Needs_PO__c
	
	return (
		<div className='content-wrapper opportunities'>
			{submitted !== null ? (
				<p className='tc'>{submissionMessage}</p>
			) : (
				<form onSubmit={logisticsFormOnSubmit}>
					<Contact />
					{displayShipping &&
						<Shipping />
					}
					{(Logistics_form_Needs_Travel_Info__c && isOnsite) &&
						<Travel />
					}
					{Logistics_form_Needs_Materials_info__c &&
						<Materials />
					}
					<AdditionalInfo />

					<section className='outline-section'>
						<div>
							<header><strong>Cancellation Terms</strong></header>
							<span>
								<p>I understand that I may cancel or reschedule any onsite or virtual workshop by providing InsideOut with express and written notice of cancellation or rescheduling sent to the following email address <a href={`mailto:${RD_email__c}`}>{RD_email__c}</a>. I acknowledge and agree that any cancellation or rescheduling will be subject to the following fees:</p>
								<p style={{ marginBottom: '20px', whiteSpace: 'pre-wrap' }}>{Delivery_Cancellation_Terms__c}</p>
							</span>
							<label>
								<input type='checkbox' name='Delivery_Cancellation_Terms__c' style={{ marginRight: '5px' }} required />
								I hereby confirm that the dates and times of each of the workshops I have scheduled, as reflected on my corresponding calendar invitation(s) from InsideOut, are accurate and correct, and I expressly agree to comply with each of the above cancellation and rescheduling terms and conditions.
							</label>
						</div>
					</section>

					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px 0 50px' }}>
						{submitButton}
					</div>
				</form>
			)}
		</div>
	)
}

export default LogisticsLanding
