
export const IconDropdownItem = ({ item }) => {
  return (
    <li className='' key={item.name}>
      <a href={item.route}>
        <img
          src={item.icon}
          alt='Group 4451'
          loading='lazy'
          width='76'
          height='76'
        />
        <span>{item.name}</span>
      </a>
    </li>
  )
}

export const BasicDropdownItem = ({ item }) => {
  return (
    <li className='' key={item.name}>
      <a href={item.route}>{item.name}</a>
    </li>
  )
}