import { useState, useEffect } from 'react'

export const useLocalObject = (
	storageName, // name of the storage
	storageType, // local or session storage, depending on if we want the data to persist
	defaultObject // What the object we want to set is
) => {
	const storageLocation = storageType
  const [stateStore, setStateStore] = useState(defaultObject)
  const localStore = storageLocation.getItem(storageName)
  
  useEffect(() => {
		const checkStorage = () => {
			if(!localStore){			
				storageLocation.setItem(storageName, JSON.stringify(stateStore))
			} else {
				let check = JSON.stringify(stateStore)
				if(check !== localStore){
					let data = JSON.parse(localStore)
					setStateStore(data)
				}
			}
		}
		
		checkStorage()
	}, [stateStore])

  const updateStorage = (updateObject) => {
    let string1 = JSON.stringify(stateStore)
    let string2 = JSON.stringify(updateObject)
    if((string1 !== string2) && updateObject){
      storageLocation.setItem(storageName, JSON.stringify(updateObject))
      setStateStore(updateObject)
    }
  }

  return { stateStore, updateStorage }
}
