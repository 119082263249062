import { useState, useEffect } from 'react'
import moment from 'moment'

const MeetingLink = ({ session, updateMeetingLink }) => {
  const { AddEvent_Unique_Key__c, Start_Date__c, Meeting_Link__c } = session
  const [updatedSession, setUpdatedSession] = useState(session)

  const sessionOnChange = (e) => {
    const { name, value } = e.target
    setUpdatedSession({ ...updatedSession, [name]: value })
  }

  useEffect(() => {
    updateMeetingLink(updatedSession)
  }, [updatedSession])

  const dateTitle = moment(Start_Date__c).format("MMM Do, YYYY")

  return (
    <label key={AddEvent_Unique_Key__c}>
      {dateTitle}
      <input
        name='Meeting_Link__c'
        type='text'
        required
        onChange={sessionOnChange}
        defaultValue={Meeting_Link__c}
      />
    </label>
  )
}

export default MeetingLink
