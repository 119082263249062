
const FooterList = ({ data, newTabs }) => {
  const listMap = data.links.map(item => (
    <li key={item.name}  className='footer-navigation-section-list-item'>
      <a
        href={item.link}
        target={newTabs ? '_blank' : '_self'}
        rel='noopener'
      >
        {item.name}
      </a>
    </li>
  ))

  return (
    <li className='footer-navigation-section'>
      {data.link ?
        <a
          className='footer-navigation-section-header'
          href={data.link}
          aria-haspopup='true'
          aria-expanded='false'
        >
          {data.header}
        </a>
      :
        <p className='footer-navigation-section-header' style={{margin: '0'}}>{data.header}</p>
      }
      <ul className='footer-navigation-section-list'>
        {listMap}
      </ul>
    </li>
  )
}

export default FooterList
