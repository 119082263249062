// All Modals use the same default styles.
// modalStyle='modalStyle' and className='main-modal'

import Modal from 'react-modal'
import { withRegistration } from '../../Context/RegistrationProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EditBillingAddressModal = ({
	isShowingEditBillingAddressModal,
	toggleEditBillingAddressModal,
	handleChangeRegistration,
	billingStreetOne,
	billingStreetTwo,
	billingCity,
	billingState,
	billingZipCode,
}) => {
	const updateBillingAddress = (e) => {
		e.preventDefault()
		toggleEditBillingAddressModal()
	}
	return (
		<Modal
			isOpen={isShowingEditBillingAddressModal}
			contentLabel='Edit billing address'
			modalStyle='modalStyle'
			className='main-modal'
			overlayClassName='main-modal-overlay'
			ariaHideApp={false}
		>
			<div className='main-modal-body'>
				<div className='close-icon-wrapper'>
					<FontAwesomeIcon
						icon={'times-circle'}
						className='close-icon'
						onClick={toggleEditBillingAddressModal}
					/>
				</div>
				<h3 className='tc'>Edit Billing Details: </h3>
				<form onSubmit={updateBillingAddress} autoComplete='off'>
					<fieldset>
						<label>
							Street 1:
							<input
								name='billingStreetOne'
								type='text'
								autoComplete='off'
								value={billingStreetOne}
								onChange={handleChangeRegistration}
							/>
						</label>
					</fieldset>
					<fieldset>
						<label>
							Street 2:
							<input
								name='billingStreetTwo'
								type='text'
								autoComplete='off'
								value={billingStreetTwo}
								onChange={handleChangeRegistration}
							/>
						</label>
					</fieldset>
					<fieldset>
						<label>
							City:
							<input
								name='billingCity'
								type='text'
								autoComplete='off'
								value={billingCity}
								onChange={handleChangeRegistration}
							/>
						</label>
					</fieldset>
					<fieldset className='form-columns'>
						<label>
							State:
							<input
								name='billingState'
								type='text'
								autoComplete='off'
								value={billingState}
								onChange={handleChangeRegistration}
							/>
						</label>
						<label>
							ZipCode:
							<input
								name='billingZipCode'
								type='text'
								autoComplete='off'
								value={billingZipCode}
								onChange={handleChangeRegistration}
							/>
						</label>
					</fieldset>
					<button
						className='button-secondary'
						style={{ margin: '0 auto' }}
					>
						{' '}
						Update Billing Address{' '}
					</button>
				</form>
			</div>
		</Modal>
	)
}

export default withRegistration(EditBillingAddressModal)
