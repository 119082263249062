import Moment from 'react-moment'
import moment from 'moment'
import 'moment-timezone'
import { withEvents } from '../Context/EventsProvider'
import RegistrationPanelDetails from './RegistrationPanelDetails'

const EventInformationPanel = ({
	selectedEvent,
	IocAndT3Registration3DayEvent,
	IocT3Registration2DayEvent,
	IocRegistration1DayEvent,
	registrationDetails,
}) => {
	const {
		City__c,
		State__c,
		Event_Country__c,
		Venue__c,
		Street_Address__c,
		Zip_Code__c,
		Is_Workshop_Full__c,
		Is_T3_Full__c,
		Id,
		Campaign_Class__c,
		Delivery_Format__c,
		X1_Day_Start_DateTime__c,
		X1_Day_End_Date_Time__c,
		X2_Day_Start_date_time__c,
		X2_Day_End_date_time__c,
		X3_Day_End_date_time__c,
		AddEvent_Time_zone__c,
	} = selectedEvent

	const reviewLocation =
		Delivery_Format__c === 'Virtual' ? 'Virtual Classroom' : 'Venue TBD'

	let convertTime = (time) => {
		let momentTime = moment(time)
		let addTimeZone = momentTime.tz('America/Denver').format('h:mm A')
		return addTimeZone
	}

	// Keep times in Mountain time since thats how the data comes in from salesforce.
	let timesDay1 = '8:30 AM - 4:30 PM'
	let timesDay2 = '8:30 AM - 4:30 PM'
	if (AddEvent_Time_zone__c) {
		timesDay1 = `${convertTime(X1_Day_Start_DateTime__c)} - ${convertTime(
			X1_Day_End_Date_Time__c
		)}`
		timesDay2 = `${convertTime(X2_Day_Start_date_time__c)} - ${convertTime(
			X2_Day_End_date_time__c
		)}`
	}

	const timeZone =
		AddEvent_Time_zone__c &&
		moment(X1_Day_Start_DateTime__c).tz(AddEvent_Time_zone__c).format('z')

	return (
		<aside className='outline-section registration-panel'>
			<div>
				<header>
					<h3>Details</h3>
				</header>
				<section>
					<h4>WHEN</h4>
					{Campaign_Class__c !== 'Breakthroughs' && (
						<>
							{(IocRegistration1DayEvent.IsActive ||
								IocAndT3Registration3DayEvent.IsActive) && (
								<ul
									style={{ marginTop: '8px' }}
									className='no-list'
								>
									<li>
										<strong>Workshop</strong>
									</li>
									<li className='light-font'>
										<Moment format='dddd, ll'>
											{X1_Day_Start_DateTime__c}
										</Moment>
									</li>
									{/* <li><Moment format='LT'>{X1_Day_Start_DateTime__c}</Moment> - <Moment format='LT'>{X1_Day_End_Date_Time__c}</Moment> {timeZone === 'null' ? ' ' : timeZone[0]}</li> */}
									{/* <li>8:30 AM - 4:30 PM {timeZone === 'null' ? ' ' : timeZone[0]}</li> */}
									<li className='light-font'>
										{timesDay1} ({timeZone})
									</li>
									{Is_Workshop_Full__c && (
										<span className='event-sold-out'>
											Sold Out
										</span>
									)}
								</ul>
							)}
						</>
					)}

					{(IocT3Registration2DayEvent.IsActive ||
						IocAndT3Registration3DayEvent.IsActive ||
						Campaign_Class__c === 'Breakthroughs') && (
						<ul style={{ marginTop: '8px' }} className='no-list'>
							<li>
								<strong>Certification</strong>
							</li>
							<li className='light-font'>
								<Moment format='ddd, ll'>
									{X2_Day_Start_date_time__c}
								</Moment>
								{Campaign_Class__c !== 'Breakthroughs' && (
									<>
										{' '}
										-{' '}
										<Moment format='ddd, ll'>
											{X3_Day_End_date_time__c}
										</Moment>
									</>
								)}
							</li>
							{/* <li><Moment format='LT'>{X2_Day_Start_date_time__c}</Moment> - <Moment format='LT'>{X2_Day_End_date_time__c}</Moment> {timeZone === 'null' ? ' ' : timeZone[0]}</li> */}
							{/* <li>8:30 AM - 4:30 PM {timeZone === 'null' ? ' ' : timeZone[0]}</li> */}
							<li className='light-font'>
								{timesDay2} ({timeZone})
							</li>
							{Is_T3_Full__c && (
								<span className='event-sold-out'>Sold Out</span>
							)}
						</ul>
					)}
				</section>
				<section>
					<h4>WHERE</h4>
					{Venue__c === null &&
					Campaign_Class__c !== 'Breakthroughs' ? (
						<ul className='no-list light-font'>
							<li>{reviewLocation}</li>
						</ul>
					) : Campaign_Class__c === 'Breakthroughs' ? (
						<ul className='no-list light-font'>
							<li>{City__c}</li>
						</ul>
					) : (
						<ul className='no-list light-font'>
							<li>{Venue__c}</li>
							<li>{Street_Address__c}</li>
							<li>
								{City__c},{' '}
								{!Event_Country__c ||
								Event_Country__c === 'United States'
									? State__c
									: Event_Country__c}{' '}
								{Zip_Code__c}
							</li>
						</ul>
					)}
				</section>
				{registrationDetails && <RegistrationPanelDetails />}
			</div>
		</aside>
	)
}

export default withEvents(EventInformationPanel)
