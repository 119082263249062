// All Modals use the same default styles.
// modalStyle='modalStyle' and className='main-modal'

import Modal from 'react-modal'
import { withRegistration } from '../../Context/RegistrationProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EditBillingContactModal = ({
	isShowingEditBillingContactModal,
	toggleEditBillingContactModal,
	handleChangeRegistration,
	billingFirstName,
	billingLastName,
	billingEmail,
	billingTitle,
	billingCompany,
	billingPhone,
}) => {
	const updateBillingContact = (e) => {
		e.preventDefault()
		toggleEditBillingContactModal()
	}

	return (
		<Modal
			isOpen={isShowingEditBillingContactModal}
			contentLabel='Edit User'
			modalStyle='modalStyle'
			className='main-modal'
			overlayClassName='main-modal-overlay'
			ariaHideApp={false}
		>
			<div className='main-modal-body'>
				<div className='close-icon-wrapper'>
					<FontAwesomeIcon
						icon={'times-circle'}
						className='close-icon'
						onClick={toggleEditBillingContactModal}
					/>
				</div>
				<h3 className='tc'>Edit Billing Details: </h3>
				<form onSubmit={updateBillingContact} autoComplete='off'>
					<fieldset className='form-columns'>
						<label>
							First Name:
							<input
								name='billingFirstName'
								type='text'
								autoComplete='off'
								value={billingFirstName}
								onChange={handleChangeRegistration}
							/>
						</label>
						<label>
							Last Name:
							<input
								name='billingLastName'
								type='text'
								autoComplete='off'
								value={billingLastName}
								onChange={handleChangeRegistration}
							/>
						</label>
					</fieldset>

					<fieldset className='form-columns'>
						<label>
							Email:
							<input
								name='billingEmail'
								type='email'
								autoComplete='off'
								value={billingEmail}
								onChange={handleChangeRegistration}
							/>
						</label>
						<label>
							Phone:
							<input
								name='billingPhone'
								type='tel'
								autoComplete='off'
								pattern='[0-9-_.]{1,30}'
								value={billingPhone}
								onChange={handleChangeRegistration}
							/>
						</label>
					</fieldset>
					<fieldset className='form-columns'>
						<label>
							Company:
							<input
								name='billingCompany'
								type='tel'
								autoComplete='off'
								value={billingCompany}
								onChange={handleChangeRegistration}
							/>
						</label>
						<label>
							Title:
							<input
								name='billingTitle'
								type='text'
								autoComplete='off'
								value={billingTitle}
								onChange={handleChangeRegistration}
							/>
						</label>
					</fieldset>

					<button
						className='button-secondary'
						style={{ margin: '0 auto' }}
					>
						{' '}
						Update Billing Details{' '}
					</button>
				</form>
			</div>
		</Modal>
	)
}

export default withRegistration(EditBillingContactModal)
