import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const WorkbookGate = () => {
    let fileName = useParams()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [checked, setChecked] = useState(false)

    let fileToAccess = ''
    let product = ''
    let routeExists = false
    let displayName = ''

    switch(fileName.id){
        case 'coaching-workbook':
            fileToAccess = 'https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut-Coaching_Participant-Workbook_Virtual_0420.pdf'
            product = 'ioc-workbook-en'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-tools':
            fileToAccess = 'https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut%20Coaching%20Tools_Virtual_0420_ENG%20WK%20and%20Cards.pdf'
            product = 'ioc-tools-en'
            displayName = 'InsideOut Coaching Tools'
            routeExists = true
            break;
        case 'coaching-workbook-dutch':
            fileToAccess = 'https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut%20Coaching%20Participant%20Workbook%20and%20Tools_Virtual_0420_NLD.pdf'
            product = 'ioc-workbook-nld'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
            
        case 'coaching-workbook-spanish':
            fileToAccess = "https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut%20Coaching%20Participant%20Workbook%20and%20Tools_Virtual_0420_LSP.pdf"
            product = 'ioc-workbook-es'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-workbook-french':
            fileToAccess = "https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut-Coaching_Participant-Workbook%20and%20Tools_Virtual_0420_FRE.pdf"
            product = 'ioc-workbook-fr'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-workbook-french-canadian':
            fileToAccess = "https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/IOC_4.0%20Participant%20Workbook%20and%20Tools%20Virtual%2010-027-02-04-21_CFR.pdf"
            product = 'ioc-workbook-fr-ca'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-workbook-german':
            fileToAccess = "https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut-Coaching_Participant-Workbook%20and%20Tools_Virtual_0420_DEU.pdf"
            product = 'ioc-workbook-de'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-workbook-polish':
            fileToAccess = 'https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut%20Coaching%20Participant%20Workbook%20and%20Tools_Virtual_0420_POL.pdf'
            product = 'coaching-workbook-polish'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-workbook-italian':
            fileToAccess = 'https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut%20Coaching%20Participant%20Workbook%20and%20Tools_Virtual_0420_ITA.pdf'
            product = 'coaching-workbook-italian'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-workbook-chinese-traditional':
            fileToAccess = "https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut-Coaching%20Participant%20Workbook%20and%20Tools_Virtual_0420_TCH.pdf"
            product = 'ioc-workbook-zh-HANT'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-workbook-chinese-simplified':
            fileToAccess = "https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut-Coaching%20Participant%20Workbook%20and%20Tools_Virtual_0420_CHI.pdf"
            product = 'ioc-workbook-zh-HANS'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-workbook-portuguese-brazil':
            fileToAccess = "https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut%20Coaching%20Participant%20Workbook%20and%20Tools_Virtual_0420_BPO.pdf"
            product = 'ioc-workbook-pt-br'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'coaching-workbook-japanese':
            fileToAccess = "https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/ioc/InsideOut-Coaching%20Participant%20Workbook%20and%20Tools_Virtual_0420_JPN.pdf"
            product = 'ioc-workbook-jp'
            displayName = 'InsideOut Coaching'
            routeExists = true
            break;
        case 'iolab-english':
            fileToAccess = 'https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/lab/InsideOut-Coaching-LAB_Participant-Workbook_Virtual_0100.pdf'
            product = 'io-lab-en'
            displayName = 'InsideOut Lab'
            routeExists = true
            break;
        case 'iolab-spanish':
            fileToAccess = 'https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/lab/InsideOut-Coaching-LAB_Participant-Workbook_Virtual_0100_LSP.pdf'
            product = 'io-lab-es'
            displayName = 'InsideOut Lab'
            routeExists = true
            break;
        case 'breakthroughs-workbook':
            fileToAccess = 'https://insideoutdev.sfo2.digitaloceanspaces.com/product_assets/bt/Breakthroughs%20Participant%20Workbook_Virtual_0100_ENG.pdf'
            product = 'breakthroughs-workbook'
            displayName = 'Breakthoughs Workbook'
            routeExists = true
            break;
        default:
            fileToAccess = '/404'
            routeExists = false
            break;
    }

    useEffect(() => {
        let accessToken = localStorage.getItem(`canAccessFile-${fileName.id}`)
        if(accessToken !== null || !routeExists) window.location = fileToAccess
    }, [fileName.id, fileToAccess, routeExists])

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { 
            firstName: firstName,
            lastName: lastName,
            email: email,
            product: product,
            fileAccessed: fileName.id
        }

        axios.post('/v1/file-access-tracking', data)
            .then(() => {
                localStorage.setItem(`canAccessFile-${fileName.id}`, true)
                window.location = fileToAccess
            })
            .catch(err => err)
    }
    

    return (
        <div className='workbook-gate'>
            <form onSubmit={(e) => handleSubmit(e)}>
                <header>Access Course Resources</header>
                <input type="text" placeholder='First Name' name="firstName" onChange={(e) => setFirstName(e.target.value)} required/>
                <input type="text" placeholder='Last Name' name="lastName" onChange={(e) => setLastName(e.target.value)} required />
                <input type="email" placeholder='Work Email Address' name="email" onChange={(e) => setEmail(e.target.value)} required/>
                
                <label>
                    <input type="checkbox" onChange={() => setChecked(!checked)} required /> 
                    By checking this box, you agree to the following terms and conditions: The participant materials for {displayName} (the “Materials”) are the sole and exclusive property of InsideOut Development, LLC, and you as the user shall obtain no rights or title to the Materials. You agree that you will not copy or distribute the Materials in any manner. By entering herein, you also agree to the terms of the InsideOut Development, LLC Privacy Policy.</label>
                <button disabled={!checked}>Submit</button>
            </form>
        </div>
    )
}

export default WorkbookGate
