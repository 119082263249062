import { useEffect, useState } from 'react'
import { stripeAPIKey } from '../config/Config'
import { withEvents } from '../Context/EventsProvider'
import { withRegistration } from '../Context/RegistrationProvider'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { rootDomain } from '../config/Config'
import moment from 'moment'
import 'moment-timezone'
import Hero from '../Components/Hero'
import EventInformationPanel from './EventInformationPanel'
// Steps
import ProgramSelectionAndParticipants from './Modules/ProgramSelectionAndParticipants'
import BillingInfo from './Modules/BillingInfo'
import PaymentInfo from './Modules/PaymentInfo'
import OrderReviewAndConfirmation from './Modules/OrderReviewAndConfirmation'
import Loader from '../Components/Loader'
import BackgroundCircles from '../Components/BackgroundCircles'

const EventRegistration = ({
	getOneEvent,
	match,
	currentStep,
	selectedEvent,
	clearRegistrationProviderState,
	validateInputs,
	windowReset,
	orderDidProcess,
}) => {
	const [showLoading, setShowLoading] = useState(true)
	const [showError, setShowError] = useState(false)
	validateInputs()

	useEffect(() => {
		windowReset()
	}, [windowReset])

	useEffect(() => {
		getOneEvent(match.params.id)
	}, [getOneEvent, match])

	useEffect(() => {
		return () => clearRegistrationProviderState()
	}, [clearRegistrationProviderState])

	// If the selectedEvent dosent display within 3s, show the error
	// Showing the error is done via inline styling below
	useEffect(() => {
		const delay = 10000
		
		let loadingTimeout = setTimeout(() => {
			if (!selectedEvent.Id) {
				setShowLoading(false)
				setShowError(true)
			}
		}, delay)

		if (selectedEvent.Id) {
			setShowLoading(false)
			setShowError(false)
		}
		
		return () => {
			clearTimeout(loadingTimeout)
		}
	}, [selectedEvent.Id])

	const {
		Delivery_Format__c,
		Metro_Area__c,
		State__c,
		Event_Country__c,
		Campaign_Class__c,
		AddEvent_Time_zone__c,
		X1_Day_Start_DateTime__c,
	} = selectedEvent

	const isGROW = Campaign_Class__c === 'GROW Coaching Parent'

	const virtualTimeZone =
		X1_Day_Start_DateTime__c &&
		moment(X1_Day_Start_DateTime__c).tz(AddEvent_Time_zone__c).format('z')

	const locationMetro =
		Delivery_Format__c === 'Virtual'
			? `Virtual Classroom ${virtualTimeZone} `
			: `${Metro_Area__c}, ${
					!Event_Country__c || Event_Country__c === 'United States'
						? State__c
						: Event_Country__c
			  }`
	const eventHeader =
		Campaign_Class__c === 'Breakthroughs'
			? 'InsideOut Breakthroughs™'
			: isGROW
			? 'GROW Coaching Workshop & Facilitator Certification'
			: 'InsideOut Coaching Workshop & Facilitator Certification'

	return (
		<main className='registrations event-registrations'>
			<BackgroundCircles />
			<Hero
				titleText={showError || showLoading ? '' : 'Event Registration'}
				subtitle={showError || showLoading ? '' : eventHeader}
				caption={showError || showLoading ? '' : locationMetro}
			/>
			{showLoading && !showError ? (
				<div className='loader-wrap'>
					<Loader />
				</div>
			) : showError ? (
				<section
					className='content-wrapper tc'
					style={!showError ? { color: 'white' } : {}}
				>
					<p>Unable to find the requested event.</p>
					<p>
						Please contact events@insideoutdev.com or 1-888-262-2448
						for assistance.
					</p>
					<a
						href={`${rootDomain}/events`}
						style={!showError ? { color: 'white' } : {}}
					>
						Return to Events page
					</a>
				</section>
			) : (
				<section className='fr-b content-wrapper'>
					<article className='registrations-content outline-section'>
						<div>
							<aside className='event-registrations-progress tc'>
								<strong
									className='active'
									style={{
										borderRadius: '4px 0 0 0',
									}}
								>
									{' '}
									Personal Info{' '}
								</strong>
								<strong
									className={currentStep >= 3 ? 'active' : ''}
									style={{ margin: '0 2px' }}
								>
									{' '}
									Payment Info{' '}
								</strong>
								<strong
									className={currentStep >= 5 ? 'active' : ''}
									style={{
										borderRadius: '0 4px 0 0',
									}}
								>
									{' '}
									Confirmation{' '}
								</strong>
							</aside>
							<section>
								{currentStep === 1 ? (
									<ProgramSelectionAndParticipants />
								) : currentStep === 2 ? (
									<OrderReviewAndConfirmation
										isGROW={isGROW}
									/>
								) : currentStep === 3 ? (
									<BillingInfo />
								) : currentStep === 4 ? (
									<OrderReviewAndConfirmation
										isGROW={isGROW}
									/>
								) : currentStep === 5 ? (
									<StripeProvider apiKey={stripeAPIKey}>
										<Elements>
											<PaymentInfo />
										</Elements>
									</StripeProvider>
								) : (
									<OrderReviewAndConfirmation
										isGROW={isGROW}
									/>
								)}
							</section>

							{orderDidProcess === false && (
								<aside>
									<p>
										Your order did not process successfully.
										Please try again or contact
										events@insideoutdev.com or
										1-888-262-2448 for assistance.
									</p>
								</aside>
							)}
							{/* {currentStep === 2 && <div className='registrations-white-space'></div>} */}
						</div>
					</article>
					<EventInformationPanel registrationDetails={true} />
				</section>
			)}
		</main>
	)
}

export default withEvents(withRegistration(EventRegistration))
