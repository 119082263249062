import { useState, useEffect } from 'react'
import { useResponsivity } from '../../Hooks/useCurrentWidth'
import Navbar from './Navbar'

const Header = () => {
	let isMobile = useResponsivity(1025)
	const [showNavbar, setShowNavbar] = useState(false)
	const [scrolled, setScrolled] = useState(false)

	const unscrolledDisplay = {backgroundColor: 'transparent'}
	const scrolledDisplay = {backgroundColor: '#343434', backdropFilter: 'blur(8px)'}

	const changeHeaderBackground = () => {
		window.scrollY >= 1 ? setScrolled(true) : setScrolled(false)
	}

	useEffect(() => {
		// window.scrollTo(0, 0)
		window.addEventListener('scroll', changeHeaderBackground)
		return () => removeEventListener('scroll', changeHeaderBackground)
		// eslint-disable-next-line
	}, [])

	return (
		<header className='header' style={(!scrolled && !showNavbar) ? unscrolledDisplay : scrolledDisplay}>
			<nav className='header-wrap'>
				<a href='https://insideoutdev.com' className='header-logo'>
					<img
						src='https://insideoutdev.com/hubfs/InsideOut/Images/logo.svg'
						alt='logo'
						width='182'
						height='44'
					/>
				</a>

				<div className='hamburger' onClick={() => setShowNavbar(!showNavbar)}>
					<span className={`line line-1${showNavbar ? '-active' : ''}`}></span>
					<span className={`line line-2${showNavbar ? '-active' : ''}`}></span>
					<span className={`line line-3${showNavbar ? '-active' : ''}`}></span>
				</div>

				{(showNavbar || !isMobile) &&
					<Navbar isMobile={isMobile} />
				}
				
				{!isMobile &&
					<div className="header-cta">
          	<a className="cta_button button button-gradient" href="https://insideoutdev.com/cs/c/?cta_guid=287db414-2fde-41d0-b75e-7a56f35ac051&amp;signature=AAH58kFibKoXo-7z3Vz_tK_Aqg9t9sLiFQ&amp;pageId=121166847872&amp;placement_guid=603cb592-ec55-4818-8b78-d4dc9b3fd78c&amp;click=a7f2edb6-71a2-40ad-b9d3-8242f5ff2eb3&amp;hsutk=550987224078c1d1ac5ebf5d078f3f07&amp;canon=https%3A%2F%2Finsideoutdev.com%2F&amp;portal_id=4127087&amp;redirect_url=APefjpGmsEbuy-vI29VRV9tVJXcBqMcDnxph8Afp5Kx1QabYEp-SHcNfZ0qB9cSR3IiGUCTV-pRs3bks9-jiB53H6QCsG5sqotZsjuBRW1gw3rkuUmvY98T5dzX8AzUOJhFCNu_zTdhtc1uU63AkQT_LLmQZ0Qi7nDYDT6I6mBQKe6zLBvFit40h1sMvY-DrXHIOtJdeSQyk-s6yxXCEQ0iyQLJk6j52EkWSJ6xNq8MC2b9ovEkL_8wLLkq0A-gok8Z59jN4nnY4U-bbB_ZnvsbIqXJzTQ-qpA&amp;__hstc=253604438.550987224078c1d1ac5ebf5d078f3f07.1691157306300.1695049646469.1695052630395.24&amp;__hssc=253604438.3.1695052630395&amp;__hsfp=1375341707&amp;contentType=standard-page" cta_dest_link="https://insideoutdev.com/lets-talk" title="Contact Us">Contact Us</a>
					</div>
				}
				
			</nav>
		</header>
	)
}

export default Header
