/* eslint-disable no-undef */
let stripeKey;
let iodFrontEndUrl;
let logrocketId;
let rootDomain;

if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'development'){
    stripeKey = process.env.REACT_APP_STRIPE_TEST_API_KEY
    iodFrontEndUrl = 'http://localhost:3000'
    logrocketId = 'tqx5ak/testing-zs3ei'
    rootDomain = 'https://dev.insideoutdev.com'
} else if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'test'){
    stripeKey = process.env.REACT_APP_STRIPE_TEST_API_KEY
    iodFrontEndUrl = 'https://register-dev.insideoutdev.com'
    logrocketId = 'tqx5ak/testing-zs3ei'
    rootDomain = 'https://dev.insideoutdev.com'
} else if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'){
    stripeKey = process.env.REACT_APP_STRIPE_LIVE_API_KEY
    iodFrontEndUrl = 'https://register.insideoutdev.com'
    rootDomain = 'https://insideoutdev.com'
    logrocketId = 'tqx5ak/iod-rebuild-production'
}

module.exports = {
    IOCT3PriceString : process.env.REACT_APP_IOCT3_PRICE,
    T3PriceString : process.env.REACT_APP_T3_PRICE,
    IOCPriceString : process.env.REACT_APP_IOC_PRICE,
    breakthroughsPriceString : process.env.REACT_APP_BREAKTHROUGHS_PRICE,
    stripeAPIKey: stripeKey,
    iodFrontEndUrl: iodFrontEndUrl,
    logrocketId: logrocketId,
    rootDomain: rootDomain,
    nodeEnvironment: process.env.REACT_APP_CUSTOM_NODE_ENV,
    googleAnalyticsTag: process.env.REACT_APP_GOOGLE_ANALYTICS_TAG,
    adobeConnectWorkshopLink: 'http://insideout.adobeconnect.com/iocallday/',
    adobeConnectCertificationLink: 'http://insideout.adobeconnect.com/ioccert/',
    emailSenderAddress: process.env.REACT_APP_MAILER_ADDRESS
}
