import 'react-app-polyfill/ie9' // Enables react to work on IE 9 and above
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import './sass/main.scss'
import App from './App'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import EventsProvider from './Context/EventsProvider'
import RegistrationProvider from './Context/RegistrationProvider'
import { FacilitatorRequestProvider } from './Context/FacilitatorRequestProvider'
import { nodeEnvironment } from './config/Config'
import { initGoogleAnalytics, googlePageView } from './shared/GoogleAnalyticsTracking'

//  Configures Google Analytics on production server only
if( nodeEnvironment === 'production' ){
  initGoogleAnalytics()
  googlePageView()
}

//  Removes unnecessary react-dom warnings until Facebook updates their package 
if( nodeEnvironment === 'development' ){
  const warn = console.warn;
  const logWarning = (...warnings ) => {
    let showWarning = true
    warnings.forEach(warning => {
      if(warning.includes("componentWillMount"))  showWarning = false;
    });
    if(showWarning) warn(...warnings);
  }
  console.warn  = logWarning
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <EventsProvider>
    <RegistrationProvider>
      <FacilitatorRequestProvider>
        <Router onUpdate={() => window.scrollTo(0, 0)} history={createBrowserHistory()}>
          <App />
        </Router>
      </FacilitatorRequestProvider>
    </RegistrationProvider>
  </EventsProvider>
)
