import { withEvents } from '../Context/EventsProvider'

const Confirmation = ({ updateStorage, selectedEvent }) => {
  const finishRegistration = () => {
    updateStorage({hasAccess: false, submitted: false})
  }

  let addEventId = /[^/]*$/.exec(selectedEvent.AddEvent_1_Day_link__c)[0]
  const calendars = ['apple', 'google', 'office365', 'outlook', 'outlookcom', 'yahoo']

  const calendarButtons = calendars.map(item => (
    <a key={item} href={`https://www.addevent.com/event/${addEventId}+${item}`} title={item} target='_blank' style={{ display:'inline' }}>
      <img src={`https://cdn.addevent.com/libs/imgs/icon-emd-share-${item}-t1.png`} alt={item} width='45' border='0' style={{ width:'45px', display:'inline' }} />
    </a>
  ))
  
  return (
    <section>
      <h3>Registration Complete</h3>
      <p>Thank you for registering. You will receive an order confirmation email shortly. If you don't receive these emails within the hour, please check your junk folder and add InsideOut Development to your email contact list.</p>

      {/* Add to Calendar buttons */}
      <p style={{ margin:'10px 0px', textAlign:'center', fontSize:'17px', lineHeight:'150%', fontWeight:'bold' }}>
        Add event to calendar
      </p>
      <p style={{ margin:'0px 0px 10px 0px', textAlign:'center', fontSize:'0px' }}>
        {calendarButtons}
      </p>

      <p>For additional support, contact <a href='mailto:events@insideoutdev.com'>events@insideoutdev.com</a> or <a href='tel:1-888-262-2448'>888-262-2448</a>.</p>
      <button className='button-primary' onClick={finishRegistration}>Complete Registration</button>
    </section>
  )
}

export default withEvents(Confirmation)
