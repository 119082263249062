// This feature is used for free registrations we send out to clients.
import { useEffect, useState } from 'react'
import { withEvents } from '../Context/EventsProvider'
import { withRegistration } from '../Context/RegistrationProvider'
import { useLocalObject } from '../Hooks/useLocalObject'
import Hero from '../Components/Hero'
import WorkshopInfoPanel from './WorkshopInfoPanel'
import AccessGate from './AccessGate'
import ParticipantForm from './ParticipantForm'
import Confirmation from './Confirmation'
import BackgroundCircles from '../Components/BackgroundCircles'
import Loader from '../Components/Loader'
import moment from 'moment'
import 'moment-timezone'


const ProspectRegistration = ({
	match,
	selectedEvent,
	getWorkshop
}) => {
	const [showLoading, setShowLoading] = useState(true)
	const [showError, setShowError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')

	let storeName = 'iodPromoRegistration'
	let defaultStore = { hasAccess: false, submitted: false }
	const { stateStore, updateStorage } = useLocalObject(storeName, sessionStorage, defaultStore)

	useEffect(() => {
		getWorkshop(match.params.id)
	}, [ getWorkshop, match ])

	useEffect(() => {
		const delay = 5000
		
		let loadingTimeout = setTimeout(() => {
			if (!selectedEvent.Id) {
				setShowLoading(false)
				setShowError(true)
				setErrorMsg('Unable to find the requested event.')
			}
		}, delay)

		if (selectedEvent.Id) {
			if(selectedEvent.Is_Workshop_Full__c){
				setShowLoading(false)
				setShowError(true)
				setErrorMsg('This event is sold out.')
			} else {
				setShowLoading(false)
				setShowError(false)
				setErrorMsg('')
			}
		}
		
		return () => {
			clearTimeout(loadingTimeout)
		}
	}, [selectedEvent.Id])

	const { Delivery_Format__c, Metro_Area__c, State__c, Event_Country__c, AddEvent_Time_zone__c, X1_Day_Start_DateTime__c } = selectedEvent
  const virtualTimeZone = X1_Day_Start_DateTime__c && moment(X1_Day_Start_DateTime__c).tz(AddEvent_Time_zone__c).format('z')
  const locationMetro = Delivery_Format__c === 'Virtual' ? `Virtual Classroom ${virtualTimeZone} ` : `${Metro_Area__c}, ${ (!Event_Country__c || Event_Country__c === 'United States') ?  State__c : Event_Country__c }` 


  return (
    <main className='registrations prospect-registrations'>
			<Hero titleText='Event Registration' subtitle={`GROW Coaching Workshop`} caption={showError || showLoading ? '' : locationMetro} />
			<BackgroundCircles />

      {showLoading && !showError ? (
				<div className='loader-wrap'>
					<Loader />
				</div>
			) : showError ? (
				<section className='content-wrapper tc' style={!showError ? {color: 'white'} : {}}>
						<p>{errorMsg}</p>
						<p>Please contact events@insideoutdev.com or 1-888-262-2448 for assistance.</p>
				</section>
			) : (
				<section className='fr-b content-wrapper'>					
					<article className='registrations-content outline-section'>
						{stateStore.submitted ?
							<Confirmation updateStorage={updateStorage} />
							:stateStore.hasAccess ?
							<ParticipantForm updateStorage={updateStorage} />
							:
							<AccessGate updateStorage={updateStorage} />
						}
					</article>

					<WorkshopInfoPanel />
				</section>
			)}
    </main>
  )
}

export default withEvents(withRegistration(ProspectRegistration))
