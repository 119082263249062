import { useState, useEffect } from 'react'
import { withRegistration } from '../Context/RegistrationProvider'

const RegistrationPanelDetails = ({
	participants,
	defaultEventPrice,
	promoCode,
	registrationEventDetails,
	totalPrice,
}) => {
	const [promoDeduction, setPromoDeduction] = useState()
	const tuitionParticipantList = participants.map((person) => {
		const index = participants.indexOf(person)
		return (
			<li key={`Participant-${index}`} index={index}>
				{person.firstName} {person.lastName}
			</li>
		)
	})

	useEffect(() => {
		let findPromoDeduction =
			defaultEventPrice * participants.length - totalPrice
		setPromoDeduction(findPromoDeduction)
	}, [defaultEventPrice, participants.length, totalPrice, promoDeduction])

	return (
		<>
			{participants.length > 0 && (
				<section>
					<h4>Tuition</h4>

					<section>
						<span
								style={{
									display: 'flex',
									justifyContent: 'space-between'
								}}
							>
							<p>
								({tuitionParticipantList.length}){' '}
								{registrationEventDetails}:
							</p>
							<p>${defaultEventPrice}</p>
						</span>
					</section>

					<section>
						<ul style={{ marginTop: '0' }}>{tuitionParticipantList}</ul>
					</section>

					{promoDeduction > 0 && (
						<section>
							<span>
								<p>Promo: {promoCode}</p>
								<p>(${promoDeduction})</p>
							</span>
						</section>
					)}

					<section>
						<span
							style={{
								borderTop: '1px solid white',
								paddingTop: '5px',
								display: 'flex',
								justifyContent: 'space-between'
							}}
						>
							<p>Subtotal:</p>
							<p>${totalPrice}</p>
						</span>
					</section>
				</section>
			)}
		</>
	)
}

export default withRegistration(RegistrationPanelDetails)
