// Used in Onsite Only
import { useFacilitatorRequest } from '../../Context/FacilitatorRequestProvider'

export const Contact = () => {
	const { requestState, logisticsFormOnChange } = useFacilitatorRequest()
	const isOnsite = requestState.Delivery_Location__c === 'Onsite'

	let title = isOnsite ? 'Onsite Contact' : 'Who should we reach out to on the day if there is an issue or emergency with the session?'
	return (
		<section className='outline-section'>
			<div>
				<header>
					<strong>{title}</strong>
				</header>
				<label>
					Name
					<input
						name='Onsite_Contact__c'
						type='text'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Onsite_Contact__c}
					/>
				</label>
				<label>
					Cellphone Number
					<input
						name='Onsite_Contact_Cell__c'
						type='tel'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Onsite_Contact_Cell__c}
					/>
				</label>
				<label>
					Email Address
					<input
						name='Onsite_Contact_Email__c'
						type='email'
						required
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Onsite_Contact_Email__c}
					/>
				</label>
			</div>
		</section>
	)
}
