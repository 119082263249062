import { useState } from 'react'
import EventParticipantForm from './EventParticipantForm'
import { withEvents } from '../../Context/EventsProvider'
import { withRegistration } from '../../Context/RegistrationProvider'
import {
	IOCT3PriceString,
	T3PriceString,
	IOCPriceString,
	breakthroughsPriceString,
} from '../../config/Config'

const ProgramSelectionAndParticipants = ({
	IocAndT3Registration3DayEvent,
	IocT3Registration2DayEvent,
	IocRegistration1DayEvent,
	handleAddParticipant,
	handleNextStep,
	selectedEvent,
	handleProgramSelection,
	participants,
	handleChangeRegistration,
	selectedEventType,
}) => {
	const [addParticipant, setAddParticipant] = useState(false)
	const {
		Is_Workshop_Full__c,
		Is_T3_Full__c,
		Delivery_Format__c,
		Campaign_Class__c,
	} = selectedEvent

	const handleAddParticipantNextStep = (ev) => {
		if (addParticipant === true) {
			handleAddParticipant(ev)
		} else {
			handleAddParticipant(ev)
			handleNextStep()
		}
	}

	const is3DayFull =
		(Is_T3_Full__c && Is_Workshop_Full__c) ||
		Is_T3_Full__c ||
		Is_Workshop_Full__c
	const is3DayActive =
		IocAndT3Registration3DayEvent.IsActive ||
		(IocRegistration1DayEvent.IsActive &&
			IocT3Registration2DayEvent.IsActive)
	const is2DayActive =
		IocT3Registration2DayEvent.IsActive ||
		IocAndT3Registration3DayEvent.IsActive
	const is1DayActive =
		IocRegistration1DayEvent.IsActive ||
		IocAndT3Registration3DayEvent.IsActive
	const isVirtual = Delivery_Format__c === 'Virtual'
	const isGROW = Campaign_Class__c === 'GROW Coaching Parent'
	const previousAttendanceDisplay = isGROW ? 'GROW' : 'InsideOut'

	return (
		<section>
			<form onSubmit={handleAddParticipantNextStep}>
				<h3>Program Selection</h3>

				{selectedEvent.Campaign_Class__c === 'Breakthroughs' ? (
					<section className='event-registrations-programs'>
						<label htmlFor='IOC 2 Day Registration'>
							<input
								type='radio'
								name='selectedEventId'
								id={selectedEvent.Id}
								value={breakthroughsPriceString}
								required={true}
								onChange={handleProgramSelection}
							/>{' '}
							Breakthroughs Facilitator Certification: $
							{breakthroughsPriceString}
						</label>
					</section>
				) : (
					<section className='event-registrations-programs'>
						{!is3DayFull && is3DayActive && (
							<label htmlFor='IOC 3 Day Registration'>
								<input
									type='radio'
									name='selectedEventId'
									id={IocAndT3Registration3DayEvent.Id}
									value={IOCT3PriceString}
									required={
										!participants.length ? true : false
									}
									onChange={handleProgramSelection}
									// checked={selectedEventType === 'IOC&T3' ? true : false}
								/>{' '}
								{isVirtual
									? 'Virtual Workshop + Virtual Certification'
									: 'Workshop + Certification (3 day)'}
								: ${IOCT3PriceString}
							</label>
						)}
						{!Is_T3_Full__c && is2DayActive && (
							<>
								<label htmlFor='IOC 2 Day Registration'>
									<input
										type='radio'
										name='selectedEventId'
										id={IocT3Registration2DayEvent.Id}
										value={T3PriceString}
										required={
											!participants.length ? true : false
										}
										onChange={handleProgramSelection}
										// Auto checked if its the only option
										// checked={
										// 	((IocRegistration1DayEvent.IsActive === false) && (IocAndT3Registration3DayEvent.IsActive === false) && (IocT3Registration2DayEvent.IsActive))
										// 	? true : null}
									/>{' '}
									{isVirtual
										? 'Virtual Certification Only'
										: 'Certification Only (2 day)'}
									: ${T3PriceString}
								</label>

								<label
									htmlFor='confirmHasPreviouslyAttended'
									className='event-registrations-programs-check'
								>
									<input
										type='checkbox'
										name='confirmHasPreviouslyAttended'
										onChange={handleChangeRegistration}
										required={
											selectedEventType === 'T3'
												? true
												: false
										}
									/>
									*Yes, I have attended a{' '}
									{previousAttendanceDisplay} Coaching
									Workshop (required for certification)
								</label>
							</>
						)}
						{!Is_Workshop_Full__c && is1DayActive && (
							<label htmlFor='IOC 1 Day Registration'>
								<input
									type='radio'
									name='selectedEventId'
									id={IocRegistration1DayEvent.Id}
									value={IOCPriceString}
									required={
										!participants.length ? true : false
									}
									onChange={handleProgramSelection}
									// Auto checked if its the only option
									// checked={
									// 	((IocRegistration1DayEvent.IsActive) && (IocAndT3Registration3DayEvent.IsActive === false) && (IocT3Registration2DayEvent.IsActive === false))
									// 	? true : null}
								/>{' '}
								{isVirtual
									? 'Virtual Workshop'
									: 'Workshop (1 day)'}
								: ${IOCPriceString}{' '}
							</label>
						)}
					</section>
				)}

				<EventParticipantForm />

				<nav style={{ display: 'flex', marginTop: '20px' }}>
					<button
						onClick={() => setAddParticipant(true)}
						className='button-primary-r'
						style={{
							borderBottomRightRadius: '0',
							borderTopRightRadius: '0',
							marginRight: '1px',
							width: '50%',
						}}
					>
						ADD ATTENDEE
					</button>
					<button
						onClick={() => setAddParticipant(false)}
						className='button-primary'
						style={{
							borderBottomLeftRadius: '0',
							borderTopLeftRadius: '0',
							marginLeft: '1px',
							width: '50%',
						}}
					>
						FORWARD
					</button>
				</nav>
			</form>
		</section>
	)
}

export default withEvents(withRegistration(ProgramSelectionAndParticipants))
