import { useFacilitatorRequest } from '../../Context/FacilitatorRequestProvider'

export const Materials = () => {
	const { requestState, logisticsFormOnChange } = useFacilitatorRequest()
	
	return (
		<section className='outline-section'>
			<div>
				<header>
					<strong>Materials</strong>
				</header>
				<label>
					Anticipated Number of Participants:
					<input
						name='Attendees__c'
						type='number'
						required
						min={0}
						onChange={logisticsFormOnChange}
						defaultValue={requestState.Attendees__c}
					/>
				</label>
				<label>
					# of Kits to ship
					<input
						name='of_Materials_to_be_Shipped__c'
						type='number'
						required
						min={0}
						onChange={logisticsFormOnChange}
						defaultValue={requestState.of_Materials_to_be_Shipped__c}
					/>
				</label>
			</div>
		</section>
	)
}
