import { useState } from 'react'
import headerData from './headerData.json'
import NavbarItem from './NavbarItem'

const Navbar = ({ isMobile }) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const [openSubDropdown, setOpenSubDropdown] = useState(false)

  return (
    <section className='header-navbar'>
      <ul className='header-navbar-menu no-list'>
        {headerData.map((section, i) => {
          const hasSections = section.subsections.length > 0
          const hasDropdown = section.dropdownRoutes.length > 0
          const showArrow = hasSections || hasDropdown
          
          return (
            <NavbarItem 
              key={i} 
              section={section} 
              hasDropdown={hasDropdown} 
              hasSections={hasSections} 
              showArrow={showArrow} 
              openDropdown={openDropdown} 
              setOpenDropdown={setOpenDropdown}
              openSubDropdown={openSubDropdown} 
              setOpenSubDropdown={setOpenSubDropdown}
              isMobile={isMobile}
            />
          )
        })}
      </ul>

      {isMobile &&
        <div className="header-cta">
          <a className="cta_button button button-gradient" href="https://insideoutdev.com/cs/c/?cta_guid=287db414-2fde-41d0-b75e-7a56f35ac051&amp;signature=AAH58kFibKoXo-7z3Vz_tK_Aqg9t9sLiFQ&amp;pageId=121166847872&amp;placement_guid=603cb592-ec55-4818-8b78-d4dc9b3fd78c&amp;click=a7f2edb6-71a2-40ad-b9d3-8242f5ff2eb3&amp;hsutk=550987224078c1d1ac5ebf5d078f3f07&amp;canon=https%3A%2F%2Finsideoutdev.com%2F&amp;portal_id=4127087&amp;redirect_url=APefjpGmsEbuy-vI29VRV9tVJXcBqMcDnxph8Afp5Kx1QabYEp-SHcNfZ0qB9cSR3IiGUCTV-pRs3bks9-jiB53H6QCsG5sqotZsjuBRW1gw3rkuUmvY98T5dzX8AzUOJhFCNu_zTdhtc1uU63AkQT_LLmQZ0Qi7nDYDT6I6mBQKe6zLBvFit40h1sMvY-DrXHIOtJdeSQyk-s6yxXCEQ0iyQLJk6j52EkWSJ6xNq8MC2b9ovEkL_8wLLkq0A-gok8Z59jN4nnY4U-bbB_ZnvsbIqXJzTQ-qpA&amp;__hstc=253604438.550987224078c1d1ac5ebf5d078f3f07.1691157306300.1695049646469.1695052630395.24&amp;__hssc=253604438.3.1695052630395&amp;__hsfp=1375341707&amp;contentType=standard-page" cta_dest_link="https://insideoutdev.com/lets-talk" title="Contact Us">Contact Us</a>
        </div>
      }
    </section>
  )
}

export default Navbar
