import React, { Component } from 'react'
import axios from 'axios'

const baseURL = '/v1/iod/events'
const EventsContext = React.createContext()

class EventsProvider extends Component {
    constructor(){
        super()
        this.state = {
            selectedEvent: {},
            IocRegistration1DayEvent: {},
            IocT3Registration2DayEvent: {},
            IocAndT3Registration3DayEvent: {},
            eventError: false,
            eventErrorMessage: ''
        }
    }

    getOneEvent = async (id) => {
        try {
            let res = await axios.get(`${baseURL}/${id}`)
            let data = res.data[0]
            this.setState({ selectedEvent: data })
            await this.getChildrenEventsOfParentEvent(data.Id)
        } catch (error) {
            console.error(error)
        }
    }

    getWorkshop = async (id) => {
        try {
            let res = await axios.get(`${baseURL}/workshop/${id}`)
            this.setState({ selectedEvent: res.data })
        } catch (error) {
            console.error(error)
        }
    }

    getChildrenEventsOfParentEvent = async (id) => {
        try {
            let res = await axios.get(`${baseURL}/${id}/children`)
            const availableChildren = res.data.filter(event => event.Trigger_Overflow__c !== true)
            availableChildren.forEach(childEvent => {
                if(childEvent.Campaign_Class__c === 'IOC and T3 Registration' || childEvent.Campaign_Class__c === 'GROW Coaching and T3 Registration'){
                    this.setState({ IocAndT3Registration3DayEvent: childEvent })
                }
                if(childEvent.Campaign_Class__c === 'IOC Registration' || childEvent.Campaign_Class__c === 'GROW Coaching Registration'){
                    this.setState({ IocRegistration1DayEvent: childEvent })
                }
                if(childEvent.Campaign_Class__c === 'IOCT3 Registration' || childEvent.Campaign_Class__c === 'GROW Coaching T3 Registration'){
                    this.setState({ IocT3Registration2DayEvent: childEvent })
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    render(){
        return (
            <EventsContext.Provider 
                value={{
                    ...this.state,
                    getOneEvent : this.getOneEvent,
                    getWorkshop: this.getWorkshop
                }}>
                { this.props.children }
            </EventsContext.Provider>
        )
    }
}

export default EventsProvider

export const withEvents = C => props => (
    <EventsContext.Consumer>
        {value => <C {...props} {...value}/>}
    </EventsContext.Consumer>
)
