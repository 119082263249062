import Moment from 'react-moment'
import moment from 'moment'
import 'moment-timezone'
import { withEvents } from '../Context/EventsProvider'

const WorkshopInfoPanel = ({ selectedEvent }) => {
  const {
    City__c,
    State__c,
    Event_Country__c,
    Venue__c,
    Street_Address__c,
    Zip_Code__c,
    Is_Workshop_Full__c,
    Delivery_Format__c,
    X1_Day_Start_DateTime__c,
    X1_Day_End_Date_Time__c,
    AddEvent_Time_zone__c
  } = selectedEvent

  const reviewLocation = Delivery_Format__c === 'Virtual' ? 'Virtual Classroom' : 'Venue TBD'

  let convertTime = (time) => {
    let momentTime = moment(time)
    let addTimeZone = momentTime.tz('America/Denver').format('h:mm A')
    return addTimeZone
  }

  let timesDay1 = '8:30 AM - 4:30 PM'
  if(AddEvent_Time_zone__c){
    timesDay1 = `${convertTime(X1_Day_Start_DateTime__c)} - ${convertTime(X1_Day_End_Date_Time__c)}`
  }

  const timeZone = AddEvent_Time_zone__c && moment(X1_Day_Start_DateTime__c).tz(AddEvent_Time_zone__c).format('z')

  return (
    <aside className='outline-section registration-panel'>
      <div>
        <header>
          <h3>Details</h3>
        </header>
        <section>
          <h4>WHEN</h4>
          <ul className='no-list'>
            <li><strong>Workshop</strong></li>
            <li className='light-font'><Moment format='dddd, ll'>{X1_Day_Start_DateTime__c}</Moment></li>
            <li className='light-font'>{timesDay1} ({timeZone})</li>
            {Is_Workshop_Full__c && <span cstyle={{color: 'red'}}>Sold Out</span>}
          </ul>
        </section>
        <section>
          <h4>WHERE</h4>
          {Venue__c === null ? 
            <ul className='no-list light-font'>
              <li>{reviewLocation}</li>
            </ul>
            :
            <ul className='no-list light-font'>
              <li>{Venue__c}</li>
              <li>{Street_Address__c}</li>
              <li>{City__c}, { (!Event_Country__c || Event_Country__c === 'United States') ?  State__c : Event_Country__c } {Zip_Code__c}</li>
            </ul>
          }
        </section>
      </div>
    </aside>
  )
}

export default withEvents(WorkshopInfoPanel)
