
const BackgroundCircles = () => {
  return (
    <div className="circle-overflow">
      <div className="circle-wrapper large right visible">
        <div className="circle0"></div>
        <div className="circle1"></div>
        <div className="circle2"></div>
        <div className="circle3"></div>
        <div className="circle4"></div>
        <div className="circle5"></div>
      </div>
    </div>
  )
}

export default BackgroundCircles
