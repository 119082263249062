import { IconDropdownItem } from './NavDropdownItems'

const SectionDropdown = ({ subsection, openSubDropdown, setOpenSubDropdown, isMobile }) => {
  const subDropdownSelected = openSubDropdown === subsection.title
  const dropdownArrow = <svg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='https://www.w3.org/2000/svg' style={{transform: subDropdownSelected ? 'rotate(-180deg)' : ''}}><path d='M15.4476 0.324227C15.24 0.118492 14.9942 0.015625 14.7102 0.015625C14.4262 0.015625 14.1807 0.118492 13.9736 0.324227L7.89085 6.35225L1.78734 0.303653C1.59357 0.111634 1.35137 0.015625 1.06073 0.015625C0.770083 0.015625 0.52096 0.118492 0.313357 0.324227C0.105754 0.529961 0.00195313 0.773551 0.00195313 1.055C0.00195313 1.33644 0.105754 1.57976 0.313357 1.78494L7.30957 8.69762C7.39261 8.77992 7.48257 8.83834 7.57945 8.87291C7.67633 8.90747 7.78013 8.92448 7.89085 8.92393C8.00158 8.92393 8.10538 8.90665 8.20226 8.87208C8.29914 8.83752 8.3891 8.77937 8.47214 8.69762L15.4684 1.76437C15.6621 1.57235 15.759 1.33562 15.759 1.05417C15.759 0.772728 15.6552 0.529413 15.4476 0.324227Z' fill='white'></path></svg>

  const toggleDropdown = () => {
    if(subDropdownSelected){
      setOpenSubDropdown(false)
    } else {
      setOpenSubDropdown(subsection.title)
    }
  }

  return (
    <li className='navbar-dropdown-sections-item' key={subsection.title}>
      <p>{subsection.title}</p>
      <div className='header-arrow-trigger' onClick={toggleDropdown}>
        {dropdownArrow}
      </div>

      {(subDropdownSelected || !isMobile) &&
        <ul className='navbar-dropdown-sections-item-subdropdown'>
          {subsection.routes.map(item => (
            <IconDropdownItem key={item.name} item={item} />
          ))}
        </ul>
      }
    </li>
  )
}

export default SectionDropdown