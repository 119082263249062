import { useState, useEffect } from 'react'
import { withEvents } from '../Context/EventsProvider'
import { withRegistration } from '../Context/RegistrationProvider'

const ParticipantForm = ({
	updateStorage,
	prospectRegistration,
	selectedEvent,
	orderDidProcess,
}) => {
	const [errorMessage, setErrorMessage] = useState('')
	const [prospect, setProspect] = useState({
		firstName: '',
		lastName: '',
		email: '',
		company: '',
		title: '',
	})

	const prospectOnChange = (e) => {
		const { name, value } = e.target
		setProspect((prevState) => ({
			...prevState,
			[name]: value,
		}))
	}

	const handleRegister = async (e) => {
		e.preventDefault()
		let prospectCode = sessionStorage.getItem('prospectCode')
		await prospectRegistration(selectedEvent, prospect, prospectCode)
	}

	useEffect(() => {
		if (orderDidProcess) {
			updateStorage({ hasAccess: true, submitted: true })
		} else {
			setErrorMessage(
				'Something went wrong, unable to submit registration'
			)
		}
	}, [orderDidProcess])

	return (
		<section>
			<h3>Participant Information</h3>
			<form onSubmit={handleRegister}>
				<fieldset className='form-columns'>
					<label htmlFor=''>
						<p>First Name:</p>
						<input
							name='firstName'
							type='text'
							onChange={prospectOnChange}
							value={prospect.firstName}
							required
						/>
					</label>
					<label htmlFor=''>
						<p>Last Name:</p>
						<input
							name='lastName'
							type='text'
							onChange={prospectOnChange}
							value={prospect.lastName}
							required
						/>
					</label>
				</fieldset>
				<fieldset>
					<label htmlFor=''>
						<p>Email:</p>
						<input
							type='email'
							name='email'
							onChange={prospectOnChange}
							value={prospect.email}
							required
						/>
					</label>
				</fieldset>
				<fieldset className='form-columns'>
					<label htmlFor=''>
						<p>Company:</p>
						<input
							name='company'
							type='text'
							onChange={prospectOnChange}
							value={prospect.company}
							required
						/>
					</label>
					<label htmlFor=''>
						<p>Title:</p>
						<input
							type='text'
							name='title'
							onChange={prospectOnChange}
							value={prospect.title}
							required
						/>
					</label>
				</fieldset>

				<div
					style={{
						display: 'flex',
						marginTop: '20px',
						alignItems: 'center',
					}}
				>
					<button type='submit' className='button-primary'>
						Register
					</button>
					{orderDidProcess === false && (
						<p className='registration-error'>{errorMessage}</p>
					)}
				</div>
			</form>
		</section>
	)
}

export default withEvents(withRegistration(ParticipantForm))
