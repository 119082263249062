import { useFacilitatorRequest } from '../../Context/FacilitatorRequestProvider'

export const Shipping = () => {
	const { requestState, logisticsFormOnChange } = useFacilitatorRequest()
	const { Logistics_form_Needs_Billing_Info__c, Logistics_form_Needs_Shipping__c, Logistics_form_Needs_PO__c } = requestState

	let headerText = 'Shipping and Billing'
	if(Logistics_form_Needs_Billing_Info__c && Logistics_form_Needs_Shipping__c){
		headerText = 'Shipping and Billing'
	} else if(!Logistics_form_Needs_Billing_Info__c && Logistics_form_Needs_Shipping__c) {
		headerText = 'Shipping'
	} else if(Logistics_form_Needs_Billing_Info__c && !Logistics_form_Needs_Shipping__c) {
		headerText = 'Billing'
	}


	return (
		<section className='outline-section'>
			<div>
				<header>
					<strong>{headerText}</strong>
				</header>
				{Logistics_form_Needs_Shipping__c &&
					<label>
						Ship to: (or electronic)
						<input
							name='Ship_to_Address__c'
							type='text'
							required
							onChange={logisticsFormOnChange}
							defaultValue={requestState.Ship_to_Address__c}
						/>
					</label>
				}
				{Logistics_form_Needs_Billing_Info__c &&
					<>
						<label>
							Bill to
							<input
								name='Bill_to_Address__c'
								type='text'
								required
								onChange={logisticsFormOnChange}
								defaultValue={requestState.Bill_to_Address__c}
							/>
						</label>
						<label>
							Billing Email
							<input
								name='Bill_to_Email__c'
								type='email'
								required
								onChange={logisticsFormOnChange}
								defaultValue={requestState.Bill_to_Email__c}
							/>
						</label>
					</>
				}
				{Logistics_form_Needs_PO__c &&
					<label>
						PO Number (if applicable)
						<input
							name='PO_if_applicable__c'
							type='text'
							onChange={logisticsFormOnChange}
							defaultValue={requestState.PO_if_applicable__c}
						/>
					</label>
				}
			</div>
		</section>
	)
}
